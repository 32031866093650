import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'swan-offline-message',
  templateUrl: './offline-message.component.html',
  styleUrls: ['./offline-message.component.scss']
})
export class OfflineMessageComponent implements OnInit {

  constructor(private ref: MatDialogRef<OfflineMessageComponent>, @Inject(MAT_DIALOG_DATA) data: any) { }

  ngOnInit() {
  }

  dismiss() {
    this.ref.close();
  }
}
