import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'swan-install-banner',
  templateUrl: './install-banner.component.html',
  styleUrls: ['./install-banner.component.scss']
})
export class InstallBannerComponent implements OnInit {

  constructor(private ref: MatDialogRef<InstallBannerComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) { }

  ngOnInit() {
  }

  close() {
    this.ref.close();
  }

}
