<div class="progress-container mat-elevation-z1"  fxLayout="row" fxLayoutAlign="start center">
  <div class="full-height primary-bg" [style.width.%]="doneProgress" fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'has-val': numDone > 0}">
    <div *ngIf="numDone > 0" class="progress-number">{{ numDone }}</div>
  </div>
  <div class="full-height primary-bg-200" [style.width.%]="inProgress" fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'has-val': numInProgress > 0}">
    <div *ngIf="numInProgress > 0"  class="progress-number">{{ numInProgress }}</div>
  </div>
  <div class="full-height to-do" [style.width.%]="toDoProgress" fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'has-val': numToDo > 0}">
    <div *ngIf="numToDo > 0"  class="progress-number">{{ numToDo }}</div>
  </div>
</div>

