import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../classes/user';
import { DataLevel } from '../classes/data-level';
import { Task } from '../classes/task';
import { FireTimeStamp } from './shopping.service';
import { Project } from '../classes/project';

const TASK = 'task';
const PROJECT = 'project';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private _db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this._db = db;
  }

  private getStartingRef(from: DataLevel, id: string): AngularFirestoreDocument {
    if (from === DataLevel.group) {
      return this._db.collection('group-data').doc(id);
    } else {
      return this._db.collection('user-data').doc(id);
    }
  }

  public getAllTasks(user: User): Observable<Task[]> {
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<Task>(TASK).valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<Task>(TASK).valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
      map(ret => {
        if (Array.isArray(ret)) {
          return ret.map((t: any) => {
            if (t.createDate && (t.createDate as FireTimeStamp).seconds) {
              t.createDate = new Date((t.createDate as FireTimeStamp).seconds * 1000);
            }
            return new Task(t);
          });
        } else {
            return [];
        }
      })
    );
  }

  public getAllProjects(user: User): Observable<Project[]> {
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<Project>(PROJECT).valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<Project>(PROJECT).valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
      map(ret => {
        if (Array.isArray(ret)) {
          return ret.map((p: any) => {
            if (p.createDate && (p.createDate as FireTimeStamp).seconds) {
              p.createDate = new Date((p.createDate as FireTimeStamp).seconds * 1000);
            }
            if (p.dueDate && (p.dueDate as FireTimeStamp).seconds) {
              p.dueDate = new Date((p.dueDate as FireTimeStamp).seconds * 1000);
            }
            return new Project(<Project>p);
          });
        } else {
            return [];
        }
      })
    );
  }

  public getTaskByUqid(from: DataLevel, id: string, uqid: string): Observable<Task> {
    return this.getStartingRef(from, id).collection<Task>(TASK)
      .doc(uqid)
      .valueChanges()
      .pipe(
        map((ret: any) => {
          if (ret.createDate) {
            ret.createDate = new Date((ret.createDate as FireTimeStamp).seconds * 1000);
          }
          return new Task(<Task>ret);
        })
      );
  }

  public getProjectByUqid(from: DataLevel, id: string, uqid: string): Observable<Project> {
    return this.getStartingRef(from, id).collection<Project>(PROJECT)
      .doc(uqid)
      .valueChanges()
      .pipe(
        map((ret: any) => {
          if (ret.createDate) {
            ret.createDate = new Date((ret.createDate as FireTimeStamp).seconds * 1000);
          }
          if (ret.dueDate) {
            ret.dueDate = new Date((ret.dueDate as FireTimeStamp).seconds * 1000);
          }
          return new Project(<Project>ret);
        })
      );
  }

  public updateTask(from: DataLevel, id: string, task: Task): Promise<void> {
    let isNew = false;
    if (!task.uqid) {
      task.uqid = this._db.createId();
      isNew = true;
    }
    if (isNew) {
      return this.getStartingRef(from, id).collection(TASK).doc(task.uqid).set(task.simplify());
    } else {
      return this.getStartingRef(from, id).collection(TASK).doc(task.uqid).update(task.simplify());
    }
  }

  public updateProject(from: DataLevel, id: string, project: Project): Promise<void> {
    let isNew = false;
    if (!project.uqid) {
      project.uqid = this._db.createId();
      isNew = true;
    }
    for (let i = 0; i < project.tasks.length; i++) {
      const task = project.tasks[i];
      task.projectUqid = project.uqid;
      if (!task.uqid) {
        task.uqid = this._db.createId();
      }
    }
    if (isNew) {
      return this.getStartingRef(from, id).collection(PROJECT).doc(project.uqid).set(project.simplify());
    } else {
      return this.getStartingRef(from, id).collection(PROJECT).doc(project.uqid).update(project.simplify());
    }
  }

  public copyProject(to: DataLevel, id: string, project: Project): Promise<void> {
    const newProject = new Project();
    newProject.updateProject(project);
    newProject.accessId = id;
    newProject.uqid = this._db.createId();
    for (let i = 0; i < newProject.tasks.length; i++) {
      newProject.tasks[i].projectUqid = newProject.uqid;
    }
    return this.getStartingRef(to, id).collection(PROJECT).doc(newProject.uqid).set(newProject.simplify());
  }

  public deleteTask(from: DataLevel, id: string, task: Task): Promise<void> {
    return this.getStartingRef(from, id).collection(TASK).doc(task.uqid).delete();
  }

  public deleteProject(from: DataLevel, id: string, project: Project): Promise<void> {
    return this.getStartingRef(from, id).collection(PROJECT).doc(project.uqid).delete();
  }
}
