import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from 'src/app/classes/project';
import User from 'firebase/compat';
import { Group } from 'src/app/classes/group';
import moment from 'moment';

@Component({
  selector: 'swan-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {

  project: Project = new Project();
  today: Date;
  dueDate: Date;
  user: User.User;
  groups: Group[] = [];

  constructor(private ref: MatDialogRef<AddProjectComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    if (data) {
      this.user = data.user;
      this.groups = data.groups;
    }
    ref.disableClose = true;
    this.project = new Project({
      createDate: new Date()
    });
    if (this.user) {
      this.project.accessId = this.user.uid;
    }
    this.today = new Date();
  }

  ngOnInit() {
  }

  isValid(): boolean {
    return !!this.project.name;
  }

  close() {
    this.ref.close();
  }

  done() {
    if (this.dueDate) {
      this.project.dueDate = moment(this.dueDate);
    }
    this.ref.close(this.project);
  }

}
