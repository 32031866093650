import { RecipeItem } from './recipe-item';
import { trimIfExists } from '../utils/string-utils';

export class Recipe {
  public accessId?: string;
  public fileUqid?: string;
  public imgUrl?: string;
  public items?: RecipeItem[];
  public name?: string;
  public uqid?: string;

  constructor(recipe?: any) {
    this.accessId = (recipe && recipe.accessId) ? recipe.accessId : undefined;
    this.fileUqid = (recipe && recipe.fileUqid) ? recipe.fileUqid : '';
    this.imgUrl = (recipe && recipe.imgUrl) ? recipe.imgUrl : '';
    this.items = (recipe && recipe.items) ? recipe.items.map(i => new RecipeItem(i)) : [];
    this.name = (recipe && recipe.name) ? recipe.name : '';
    this.uqid = (recipe && recipe.uqid) ? recipe.uqid : undefined;
  }

  public simplify(): any {
    return {
      accessId: trimIfExists(this.accessId),
      fileUqid: trimIfExists(this.fileUqid),
      imgUrl: trimIfExists(this.imgUrl),
      items: this.items.map(i => i.simplify()),
      name: trimIfExists(this.name),
      uqid: trimIfExists(this.uqid),
    };
  }
}
