import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Task } from 'src/app/classes/task';
import { WorkflowStatus } from 'src/app/classes/workflow-status';

@Component({
  selector: 'swan-project-progress',
  templateUrl: './project-progress.component.html',
  styleUrls: ['./project-progress.component.scss']
})
export class ProjectProgressComponent implements OnInit, OnChanges {

  doneProgress = 0;
  inProgress = 0;
  toDoProgress = 0;
  numDone = 0;
  numInProgress = 0;
  numToDo = 0;

  @Input() tasks: Task[] = [];

  constructor() { }

  ngOnChanges() {
    this.setBars();
  }

  ngOnInit() {
    this.setBars();
  }

  setBars() {
    this.numDone = 0;
    this.numInProgress = 0;
    this.numToDo = 0;
    this.tasks.forEach((task) => {
      if (task.status === WorkflowStatus.Done) {
        this.numDone++;
      } else if (task.status === WorkflowStatus.InProgress) {
        this.numInProgress++;
      } else {
        this.numToDo++;
      }
    });
    this.doneProgress = (this.tasks.length) ? (this.numDone / this.tasks.length) * 100 : 0;
    this.inProgress = (this.tasks.length) ? (this.numInProgress / this.tasks.length) * 100 : 0;
    this.toDoProgress = (this.tasks.length) ? (this.numToDo / this.tasks.length) * 100 : 0;

  }

}
