<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Edit List</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div class="modal-content full-width" *ngIf="list" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>List Name</mat-label>
      <input matInput [(ngModel)]="list.name" (blur)="updateList()">
    </mat-form-field>

    <mat-form-field class="full-width" color="accent" appearance="outline" (click)="sd.open()">
      <mat-label>Shopping Date</mat-label>
      <input matInput [min]="today" [(ngModel)]="shoppingDate" [matDatepicker]="sd" disabled (dateChange)="updateShoppingDate()">
      <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
      <mat-datepicker #sd disabled="false"></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea matInput matTextareaAutosize matAutosizeMaxRows="6" matAutosizeMinRows="1" [(ngModel)]="list.notes" (blur)="updateList()"></textarea>
    </mat-form-field>

    <div class="full-width" fxLayout="column" fxLayoutAlign="start center"  fxLayoutGap="10px">
      <div class="full-width" *ngFor="let location of list.locations; let i = index" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field fxFlex="grow" color="accent" appearance="outline">
          <mat-label>Location {{i + 1}}</mat-label>
          <input matInput [(ngModel)]="list.locations[i]" (blur)="renameLocation(location, i)">
        </mat-form-field>
        <button mat-icon-button (click)="deleteLocation(location)" color="accent"><mat-icon>delete_outline</mat-icon></button>
      </div>
      <button class="add-location-button" class="full-width" mat-raised-button (click)="addLocation()">Add Location</button>
    </div>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="delete()">
        <mat-icon class="modal-footer-icon">delete</mat-icon>
        <div class="modal-footer-text">Delete</div>
      </div>
    </div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-footer-text">Done</div>
        <mat-icon class="modal-footer-icon">check</mat-icon>
      </div>
    </div>
  </div>
</div>
