import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/modules/core/auth/auth.service';

@Component({
  selector: 'swan-pass-reset',
  templateUrl: './pass-reset.component.html',
  styleUrls: ['./pass-reset.component.scss']
})
export class PassResetComponent implements OnInit {

  email: string;

  constructor(private ref: MatDialogRef<PassResetComponent>,
              @Inject(MAT_DIALOG_DATA) data: string,
              private authService: AuthService,
              private popup: MatSnackBar) {
    if (data) {
      this.email = data;
    }
  }

  ngOnInit(): void {
  }

  isValid(): boolean {
    return !!this.email;
  }

  close() {
    this.ref.close();
  }

  send() {
    if (this.isValid()) {
      this.authService.sendPasswordReset(this.email).then(() => {
        this.popup.open(`Password reset email sent to ${this.email}`, 'Dismiss', {duration: 5000, panelClass: ['snackbar-success']});
        this.ref.close();
      })
    }
  }

}
