import moment from 'moment';
import { TripItem } from './trip-item';
import { trimIfExists } from '../utils/string-utils';

export class Trip {
  public accessId?: string;
  public destination?: string;
  public endDate?: moment.Moment;
  public items?: Map<string, TripItem[]>;
  public name?: string;
  public notes?: string;
  public packingMode?: boolean = false;
  public startDate?: moment.Moment;
  public uqid?: string;


  constructor(trip?: any) {
    this.accessId = (trip && trip.accessId) ? trip.accessId : undefined;
    this.destination = (trip && trip.destination) ? trip.destination : '';
    this.endDate = (trip && trip.endDate) ? moment(trip.endDate) : moment().add(7, 'days');
    this.items = (trip && trip.items) ? this.initItems(trip.items) : new Map<string, TripItem[]>();
    this.name = (trip && trip.name) ? trip.name : '';
    this.notes = (trip && trip.notes) ? this.fixNotes(trip.notes) : '';
    this.packingMode = (trip && trip.packingMode !== undefined) ? trip.packingMode : false;
    this.startDate = (trip && trip.startDate) ? moment(trip.startDate) : moment();
    this.uqid = (trip && trip.uqid) ? trip.uqid : undefined;
  }

  private fixNotes(notes: any): string {
    if (Array.isArray(notes)) {
      return notes.map(n => `- ${n}`).join("\n");
    } else {
      return notes
    }
  }

  public simplify(): any {
    return {
      accessId: trimIfExists(this.accessId),
      destination: trimIfExists(this.destination),
      endDate: this.endDate.toDate(),
      items: this.simplifyItems(),
      name: trimIfExists(this.name),
      notes: trimIfExists(this.notes),
      packingMode: this.packingMode,
      startDate: this.startDate.toDate(),
      uqid: trimIfExists(this.uqid)
    };
  }

  private initItems(incomingItems: any): Map<string, TripItem[]> {
    const items = new Map<string, TripItem[]>();
    if (incomingItems instanceof Map) {
      incomingItems.forEach((value, key) => {
        items.set(key, value);
      });
    } else {
      const keys = Object.keys(incomingItems);
      keys.forEach((key) => {
        items.set(key, incomingItems[key].map(i => new TripItem(i)));
      });
    }
    return items;
  }

  private simplifyItems(): any {
    const ret = Array.from(this.items).reduce((o, [key, value]) => (
      Object.assign(o, { [key]: value.map(i => i.simplify()) })
    ), {});
    return ret;
  }

  public updateTrip(trip: Trip) {
    this.destination = trip.destination;
    this.notes = trip.notes;
    this.name = trip.name;
    this.packingMode = trip.packingMode;
    this.startDate = trip.startDate;
    this.endDate = trip.endDate;
    const newItems = trip.getItemsAsArray();
    const oldItems = this.getItemsAsArray();
    for (let i = 0; i < newItems.length; i++) {
      const sli = oldItems.find(item => item.uqid === newItems[i].uqid);
      if (sli) {
        sli.updateItem(newItems[i]);
      } else {
        const key = newItems[i].category;
        if (!this.items.get(key)) {
          this.items.set(key, []);
        }
        this.items.get(key).push(newItems[i]);
      }
    }
    for (let i = 0; i < oldItems.length; i++) {
      const sli = newItems.find(item => item.uqid === oldItems[i].uqid);
      if (!sli) {
        const key = oldItems[i].category;
        const items = this.items.get(key);
        const index = items.findIndex(j => j.uqid === oldItems[i].uqid);
        if (index !== -1) {
          items.splice(index, 1);
        }
      }
    }
  }

  public getItemsAsArray(): TripItem[] {
    const ret: TripItem[] = [];
    this.items.forEach((value, key) => {
      ret.push(...value);
    });
    return ret;
  }

  public get noProgress(): boolean {
    const itemArr = this.getItemsAsArray();
    const numChecked = itemArr.map(i => (i.checked) ? 1 : 0).filter(i => i > 0).length;
    return numChecked === 0 || itemArr.length === 0
  }

  public get totalItems(): number {
    return this.getItemsAsArray().length;

  }

  public get numberCheckedString(): string {
    const itemArr = this.getItemsAsArray();
    return `${itemArr.map(i => (i.checked) ? 1 : 0).filter(i => i > 0).length} / ${itemArr.length}`;
  }

  public get numberChecked(): number {
    return this.getItemsAsArray().filter(i => i.checked).length;
  }

  public get isDone(): boolean {
    const itemArr = this.getItemsAsArray();
    const numChecked = itemArr.map(i => (i.checked) ? 1 : 0).filter(i => i > 0).length;
    return (numChecked === itemArr.length && itemArr.length > 0);
  }

  public deleteItem(item: TripItem) {
    if (this.items.get(item.category)) {
      let index = this.items.get(item.category).findIndex(s => s.uqid === item.uqid);
      if (index !== -1) {
        this.items.get(item.category).splice(index, 1);
      }
    }
  }

  updateItem(oldItem: TripItem, newItem: TripItem) {
    let index = this.items.get(oldItem.category).findIndex(i => i.uqid == oldItem.uqid);
    if (index != -1) {
      if (oldItem.category != newItem.category) {
        this.items.get(oldItem.category).splice(index, 1);
        if (!this.items.get(newItem.category)) {
          this.items.set(newItem.category, [])
        }
        this.items.get(newItem.category).push(newItem);
      } else {
        this.items.get(oldItem.category)[index] = newItem;
      }
    }
  }
}
