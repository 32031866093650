import { trimIfExists } from '../utils/string-utils';

export class Role {
  public name?: string;
  public uqid?: string;

  constructor(role?: any) {
    this.name = (role && role.name) ? role.name : '';
    this.uqid = (role && role.uqid) ? role.uqid : undefined;
  }

  public simplify(): any {
    return {
      name: trimIfExists(<string>this.name),
      uqid: trimIfExists(<string>this.uqid),
    };
  }

}
