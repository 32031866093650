import { trimIfExists } from '../utils/string-utils';

export class StoreCategory {
  public accessId?: string;
  public icon?: string;
  public name?: string;
  public uqid?: string;

  constructor(category?: any) {
    this.accessId = (category && category.accessId) ? category.accessId : undefined;
    this.icon = (category && category.icon) ? category.icon : '';
    this.name = (category && category.name) ? category.name : '';
    this.uqid = (category && category.uqid) ? category.uqid : undefined;
  }

  public simplify(): any {
    return {
      accessId: trimIfExists(this.accessId),
      icon: trimIfExists(this.icon),
      name: trimIfExists(this.name),
      uqid: trimIfExists(this.uqid),
    };
  }
}
