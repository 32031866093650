import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';

import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { environment } from '../environments/environment';
import { CoreModule } from './modules/core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdatePromptComponent } from './components/update-prompt/update-prompt.component';
import { BrowserModule } from '@angular/platform-browser';
import { InstallBannerComponent } from './components/install-banner/install-banner.component';
import { OfflineMessageComponent } from './components/offline-message/offline-message.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routing';
import { UpgradeComponent } from './components/upgrade/upgrade.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { UserManagerComponent } from './components/user-manager/user-manager.component';
import { VerifyEmailSentComponent } from './components/verify-email-sent/verify-email-sent.component';
import { NotVerifiedPromptComponent } from './components/not-verified-prompt/not-verified-prompt.component';
import { PassResetComponent } from './components/pass-reset/pass-reset.component';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { NgxCloudScriptureModule } from 'ngx-cloud-scripture';
import { ScriptureComponent } from './components/scripture/scripture.component';

@NgModule({
  declarations: [
    AppComponent,
    UpdatePromptComponent,
    InstallBannerComponent,
    OfflineMessageComponent,
    FeedbackFormComponent,
    AboutPageComponent,
    UpgradeComponent,
    ContactUsComponent,
    UserManagerComponent,
    VerifyEmailSentComponent,
    NotVerifiedPromptComponent,
    PassResetComponent,
    DeleteUserComponent,
    ScriptureComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    SharedModule,
    CoreModule,
    NgxCloudScriptureModule.forRoot({apiKey: environment.cloudScriptureAPIKey}),
    ServiceWorkerModule.register('ngsw-worker.js', { 
      enabled: environment.production,
    }),
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
