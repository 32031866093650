<div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Add List</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div class="modal-content full-width" *ngIf="list" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-form-field class="small-form full-width" color="accent" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput (focus)="list.name = ''" [(ngModel)]="list.name">
    </mat-form-field>

    <div class="full-width" *ngIf="groups.length > 0" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
      <div class="full-width" style="color: #a6a6a6">Add to...</div>
      <mat-radio-group class="full-width" [(ngModel)]="list.accessId" fxLayout="column" fxLayoutGap="5px">
        <mat-radio-button [value]="user.uid">Private</mat-radio-button>
        <mat-radio-button [value]="group.uqid" *ngFor="let group of groups">{{group.name}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-form-field class="small-form full-width" color="accent" appearance="outline" (click)="sd.open()">
      <mat-label>Shopping Date</mat-label>
      <input matInput [min]="today" [(ngModel)]="shoppingDate" [matDatepicker]="sd" disabled>
      <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
      <mat-datepicker #sd disabled="false"></mat-datepicker>
    </mat-form-field>

    <div class="full-width" fxLayout="column" fxLayoutAlign="start center">
      <div class="full-width" *ngFor="let locationInput of locationInputs; let i = index">
        <mat-form-field class="small-form full-width" color="accent" appearance="outline" *ngIf="locationInput && (i === 0 || locationInputs[i - 1].location)">
          <mat-label>Location {{ i + 1 }}</mat-label>
          <button mat-icon-button *ngIf="locationInput.disabled" class="remove-location" matTooltip="Remove Location" color="accent" (click)="removeLocation(locationInput.location)">
              <mat-icon class="remove-location-icon">clear</mat-icon>
          </button>
          <input matInput [(ngModel)]="locationInput.location" [disabled]="locationInput.disabled" (blur)="disableInput(locationInput)">
        </mat-form-field>
      </div>
    </div>
    
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="done()">
        <div class="modal-footer-text" [ngClass]="{'disabled': !isValid()}">Done</div>
        <mat-icon class="modal-footer-icon" [ngClass]="{'disabled': !isValid()}">check</mat-icon>
      </div>
    </div>
  </div>
</div>
