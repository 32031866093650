import { trimIfExists } from '../utils/string-utils';
import { WorkflowStatus } from './workflow-status';
import moment from 'moment';
import { Priority } from './priority';
import { Comment } from './comment';

export class Task {
    public accessId?: string;
    public comments?: Comment[];
    public createDate?: moment.Moment;
    public description?: string;
    public name?: string;
    public order?: number;
    public priority?: Priority;
    public projectUqid?: string;
    public status?: WorkflowStatus;
    public uqid?: string;

    constructor(task?: any) {
      this.accessId = (task && task.accessId) ? task.accessId : undefined;
      this.comments = (task && task.comments) ? task.comments.map((c: any) => new Comment(c)) : [];
      this.createDate = (task && task.createDate) ? moment(task.createDate) : moment();
      this.description = (task && task.description) ? task.description : '';
      this.name = (task && task.name) ? task.name : '';
      this.order = (task && task.order) ? task.order : 0;
      this.priority = (task && task.priority) ? Priority.forName(task.priority) : Priority.Medium;
      this.projectUqid = (task && task.projectUqid) ? task.projectUqid : undefined;
      this.status = (task && task.status) ? WorkflowStatus.forName(task.status) : WorkflowStatus.ToDo;
      this.uqid = (task && task.uqid) ? task.uqid : undefined;
    }

    public simplify(): any {
      const ret: any = {
        accessId: trimIfExists(<string>this.accessId),
        comments: (this.comments) ? this.comments.map(c => c.simplify()) : [],
        createDate: (this.createDate) ? this.createDate.toDate() : undefined,
        name: trimIfExists(<string>this.name),
        order: this.order,
        priority: (this.priority) ? this.priority.toString() : undefined,
        status: (this.status) ? this.status.toString() : undefined,
        uqid: trimIfExists(<string>this.uqid)
      };
      if (this.description) {
        ret.description = trimIfExists(<string>this.description);
      }
      if (this.projectUqid) {
        ret.projectUqid = this.projectUqid;
      }
      return ret;
    }

    public updateTask(task: Task) {
        this.accessId = task.accessId;
        this.comments = task.comments;
        this.createDate = task.createDate;
        this.description = task.description;
        this.name = task.name;
        this.order = task.order;
        this.priority = task.priority;
        this.projectUqid = task.projectUqid;
        this.status = task.status;
        this.uqid = task.uqid;
    }
}
