<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Edit Trip</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="trip" class="modal-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>Destination</mat-label>
      <input matInput [(ngModel)]="trip.destination" (blur)="updateDestination()">
    </mat-form-field>
    <mat-form-field class="full-width" color="accent" appearance="outline" (click)="sd.open()">
      <mat-label>Start Date</mat-label>
      <input matInput [min]="today" [(ngModel)]="startDate" [matDatepicker]="sd"disabled (dateChange)="updateStartDate()">
      <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
      <mat-datepicker #sd disabled="false"></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="full-width" color="accent" appearance="outline" (click)="ed.open()">
      <mat-label>End Date</mat-label>
      <input matInput [min]="startDate" [(ngModel)]="endDate" [matDatepicker]="ed" disabled (dateChange)="updateEndDate()">
      <mat-datepicker-toggle matSuffix [for]="ed"></mat-datepicker-toggle>
      <mat-datepicker #ed disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="delete()">
        <mat-icon class="modal-footer-icon">delete</mat-icon>
        <div class="modal-footer-text">Delete</div>
      </div>
    </div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-footer-text">Done</div>
        <mat-icon class="modal-footer-icon">check</mat-icon>
      </div>
    </div>
  </div>
</div>
