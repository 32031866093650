import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { take } from 'rxjs/operators';
import { User } from 'src/app/classes/user';
import { BannerService } from 'src/app/services/banner.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

enum AboutSection {
  mission = 'mission',
  realtime = 'realtime',
  customize = 'customize',
  feedback = 'feedback'
}

@Component({
  selector: 'swan-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
  animations: [
    trigger('growShrink', [
      transition('* => void', [
          style({height: '*', opacity: '1'}),
          animate('0.3s ease-in-out', style({height: '0', opacity: '0'}))
      ]),
      transition('void => *', [
        style({height: '0', opacity: '0'}),
        animate('0.3s ease-in-out', style({height: '*', opacity: '1'}))
      ])
    ])
  ]
})
export class AboutPageComponent implements OnInit {

  user: User;
  userInit = false;

  AboutSection = AboutSection;
  activeSection: AboutSection = AboutSection.mission;

  items: GalleryItem[] = [];

  activeItem = 0;

  @ViewChild('swistibleGallery') swistibleGallery: ElementRef;


  constructor(private authService: AuthService,
              private bannerService: BannerService,
              private router: Router,
              @Inject(DOCUMENT) private document: Document) {
    this.bannerService.loading.next(true);
    this.authService.user.pipe(take(1)).subscribe((user) => {
      this.userInit = true;
      this.bannerService.loading.next(false);
      this.user = user;
      if (this.user) {
        this.user.roleObj = this.authService.myRole.value;
        this.bannerService.setBackUrl('Home');
        this.bannerService.setFooterOptions([]);
      } else {
        this.bannerService.setBackUrl('Login');
      }
    }, () => {
      this.bannerService.loading.next(false);
    });

    this.items = [
      {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_home.png',
        alt: 'about_page',
        title: 'About Swistible',
        // tslint:disable-next-line: max-line-length
        description: 'A real-time group management application for shopping lists, packing lists, and task management.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_group.png',
        alt: 'group_page',
        title: 'Real-time Group Collaboration',
        // tslint:disable-next-line: max-line-length
        description: 'Manage as many groups as you need, and invite any number of members. As you use Swistible, your data will update in real-time as your group members work.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_list.png',
        alt: 'shopping_page',
        title: 'List Management',
        // tslint:disable-next-line: max-line-length
        description: 'Make as many lists as you need. Shop from multiple locations, add notes, and check on the progress as your group works.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_shopping.png',
        alt: 'shopping_list_page',
        title: 'Easy Shopping',
        // tslint:disable-next-line: max-line-length
        description: 'Lists are broken down into custom categories to speed up your shopping experience. Everyone in your group will see changes to the list as they happen. Divide and conquer to get things done fast.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_recipe.png',
        alt: 'recipe_page',
        title: 'Shopping For Recipes (Silver Subscription)',
        // tslint:disable-next-line: max-line-length
        description: 'Create recipes for your favorite meals. Easily add the ingredients from your recipes to a pre sorted real-time shopping list.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_category.png',
        alt: 'template_shopping',
        title: 'Custom Store Categories',
        // tslint:disable-next-line: max-line-length
        description: 'Every store is different. Customize categories for your lists that match your favorite shopping center. Pick from a large list of icons for ease in organization.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_travel.png',
        alt: 'travel_page',
        title: 'Trip Management',
        // tslint:disable-next-line: max-line-length
        description: 'Schedule trips and manage your packing with ease. With a little configuration, a packing list can be made in minutes.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_trip.png',
        alt: 'trip_page',
        title: 'Simple Packing',
        // tslint:disable-next-line: max-line-length
        description: 'Packing lists are broken down into custom categories. Organization and real-time updates make it easier to remember everything you need for your outing. Flag things as a last minute item to make your departure less hectic.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_template.png',
        alt: 'template_packing',
        title: 'Custom Packing Templates',
        // tslint:disable-next-line: max-line-length
        description: 'Create templates that can speed up your packing list creation. Denote wether the item is dependant on the length of your trip.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_quick.png',
        alt: 'templates_to_trip',
        title: 'Packing Lists From Templates',
        // tslint:disable-next-line: max-line-length
        description: 'Once you have templates in place, packing get much easier. Simply select what items you need and make a list in minutes. Some items can even infer their amount by the duration of your trip.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_task.png',
        alt: 'todo_page',
        title: 'Workload Overview',
        // tslint:disable-next-line: max-line-length
        description: 'Maintain any number of projects or tasks. Create a task and assign it to a project later. Organize priorities and get real-time updates. Collaboration among your group\'s workload has never been easier.'
      }, {
        src: 'assets/swistible/swistible_min.png',
        dataSrc: 'assets/swistible/swistible_project.png',
        alt: 'project_page',
        title: 'Project Management (Silver Subscription)',
        // tslint:disable-next-line: max-line-length
        description: 'Get a detailed view of your tasks for each project you create. Move the tasks through your workflow with ease and prioritize within each category.'
      }
    ];

  }

  changeSlide(val: number) {
    this.activeItem += val;
    const el = this.swistibleGallery.nativeElement as HTMLElement;
    if (this.activeItem === 0) {
      el.style.transform = 'translateX(0)';
    } else {
      el.style.transform = `translateX(calc((-100% / ${this.items.length}) * ${this.activeItem}))`;
    }
  }

  ngOnInit() {
    this.loadImages('asyncImage');
  }

  loadImages(className: string) {
    (() => {
      'use strict';
      // Page is loaded
      const objects = document.getElementsByClassName(className);
      const objectArray = Array.from(objects);
      if (objectArray.length) {
        Array.from(objects).map((item) => {
          const htmlItem = (item as HTMLImageElement);
          // Start loading image
          const img = new Image();
          img.src = htmlItem.dataset.src;
          // Once image is loaded replace the src of the HTML element
          img.onload = () => {
            htmlItem.classList.remove(className);
            htmlItem.src = htmlItem.dataset.src;
          };
        });
      } else {
        window.setTimeout(() => {
          this.loadImages(className);
        }, 50);
      }
    })();
  }

  navigateToExternal(url: string) {
    this.document.location.href = url;
  }

  footerAction(action: string) {
    this.login();
  }

  login() {
    this.router.navigate(['/login'], {queryParams: {returnUrl: '/home'}});
  }

}


export interface GalleryItem {
  src: string;
  dataSrc: string;
  alt: string;
  title: string;
  description: string;
}
