<div class="fill-container" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px" style="position: relative;">
  <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="grow" class="logo-container primary-c" fxLayout="column" fxLayoutAlign="center center">
      <div>Swistible</div>
    </div>
  </div>
  <div class="section full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
    <div class="full-width heading" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="activeSection = AboutSection.mission">
      <div class="heading-bar" [ngClass]="{'primary-bg': activeSection === AboutSection.mission}"></div>
      <div class="heading-text accent-c">Mission</div>
    </div>
    <div class="section-text" *ngIf="activeSection === AboutSection.mission" [@growShrink]="''">
      Managing a household is hard and can take much of the limited time you get in a day. Swistible is designed to help ease some of that burden. In <span class="primary-c" style="font-style: italic;">real-time</span>,
      you can create and categorize <span class="primary-c" style="font-style: italic;">shopping lists</span> with ease, plan and pack for <span class="primary-c" style="font-style: italic;">trips</span> by refactoring saved lists, and manage your household <span class="primary-c" style="font-style: italic;">projects</span> with efficiency.
      Our goal is to cut out as much of the busy work as possible and save you time that can be better spent elsewhere.
    </div>
  </div>
  <div class="section full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
    <div class="full-width heading" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="activeSection = AboutSection.realtime">
      <div class="heading-bar" [ngClass]="{'primary-bg': activeSection === AboutSection.realtime}"></div>
      <div class="heading-text accent-c">Real-time Collaboration</div>
    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="activeSection === AboutSection.realtime" [@growShrink]="''">
      <div class="section-text">
        All of the tools in Swistible update in real-time, making it a powerful household collaboration utility. We provide shopping lists that <span class="primary-c" style="font-style: italic;">update as you shop</span>, so you can divide and conquer in the store to save time.
        Our trip management tool and project organizer operate in the same way, allowing you to <span class="primary-c" style="font-style: italic;">work together</span> on packing and organizing tasks. With Swistible, you can create and manage as many groups as you need
        and always stay up to date.
      </div>
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-icon color="accent">person</mat-icon>
        <mat-icon class="sync-icon" color="accent">sync_alt</mat-icon>
        <div style="position: relative">
          <mat-icon class="phone-icon" color="accent">smartphone</mat-icon>
          <mat-icon class="swan-inside-icon" svgIcon="swan" color="accent"></mat-icon>
        </div>
        <mat-icon class="sync-icon" color="accent">sync_alt</mat-icon>
        <mat-icon color="accent">person</mat-icon>
      </div>
    </div>
  </div>
  <div class="section full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
    <div class="full-width heading" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="activeSection = AboutSection.customize">
      <div class="heading-bar" [ngClass]="{'primary-bg': activeSection === AboutSection.customize}"></div>
      <div class="heading-text accent-c">Customizable</div>
    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="activeSection === AboutSection.customize" [@growShrink]="''">
      <div class="section-text">
        Everybody has their own way of doing things. With Swistible, you can create <span class="primary-c" style="font-style: italic;">custom categories</span> to match your favorite store 
        and save <span class="primary-c" style="font-style: italic;">packing templates</span> that contain the usual things you bring on a trip. Each user and each group has its own settings, allowing for the most <span class="primary-c" style="font-style: italic;">unique</span> experience.
      </div>
      <div class="full-width" class="custom-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-icon class="custom top-left" color="accent">shopping_cart</mat-icon>
        <mat-icon class="custom top-right" color="accent">card_travel</mat-icon>
        <mat-icon class="custom bottom-right" color="accent">all_inbox</mat-icon>
        <mat-icon class="custom bottom-left" color="accent">view_carousel</mat-icon>
        <mat-icon class="arrows top-left" color="accent">arrow_right_alt</mat-icon>
        <mat-icon class="arrows top-right" color="accent">arrow_right_alt</mat-icon>
        <mat-icon class="arrows bottom-left" color="accent">arrow_right_alt</mat-icon>
        <mat-icon class="arrows bottom-right" color="accent">arrow_right_alt</mat-icon>

        <mat-icon class="group-icon" color="accent">group</mat-icon>
      </div>
    </div>
  </div>
  <div class="section full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
    <div class="full-width heading" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="activeSection = AboutSection.feedback">
      <div class="heading-bar" [ngClass]="{'primary-bg': activeSection === AboutSection.feedback}"></div>
      <div class="heading-text accent-c">User Driven</div>
    </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="activeSection === AboutSection.feedback" [@growShrink]="''">
      <div class="section-text">
        Swistible was inspired by my wife. She would tell me that she wished there were an app to help her with various things in her day. I did my best to take her input and channel it into this application.
        User input is a <span class="primary-c" style="font-style: italic;">key element</span> in the design and future of Swistible. You can submit your feedback within this app and contribute ideas to make Swistible better for <span class="primary-c" style="font-style: italic;">everyone</span> in future updates.
      </div>
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div fxLayout="row" fxLayout="start start">
          <mat-icon color="accent">person</mat-icon>
          <mat-icon class="chat-icon" color="accent">chat</mat-icon>
        </div>
        <mat-icon color="accent">arrow_right_alt</mat-icon>
        <div style="position: relative">
          <mat-icon class="phone-icon" color="accent">smartphone</mat-icon>
          <mat-icon class="swan-inside-icon" svgIcon="swan" color="accent"></mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="full-width gallery-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon class="chevron-filler" fxFlex="noshrink" [ngClass]="{'disabled': activeItem === 0}" (click)="(activeItem !== 0) ? changeSlide(-1) : changeSlide(0)">chevron_left</mat-icon>
      <div fxFlex="grow" class="gallery">
          <div class="gallery-content" #swistibleGallery
              [style.width]="'calc(100% * ' + items.length + ')'"
              fxLayout="row" fxLayoutAlign="start center"
              [ngClass]="{'slide-1': activeItem === 1}">
              <div *ngFor="let item of items; let i = index;" class="gallery-section full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                  <img #swistibleScreenshot{{i}} fxFlex="noshrink" [src]="item.src" [alt]="item.alt"[attr.data-src]="item.dataSrc" class="gallery-image asyncImage mat-elevation-z7"/>
              
              </div>
          </div>
      </div>
      <mat-icon class="chevron-filler" fxFlex="noshrink" [ngClass]="{'disabled': activeItem === items.length - 1}" (click)="(activeItem !== items.length - 1) ? changeSlide(1) : changeSlide(0)">chevron_right</mat-icon>
    </div>
    <div class="gallery-text full-width" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="16px">
        <div class="gallery-section-title">{{items[activeItem].title}}</div>
        <div class="gallery-section-description">{{items[activeItem].description}}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div class="screenshot-dot" *ngFor="let item of items; let i = index" [ngClass]="{'active': activeItem === i}"></div>
    </div>  
  </div>
  
  <div class="full-width margin-0" fxLayout="column" fxLayoutAlign="start center">
    <div class="app-info full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <mat-icon class="angular" svgIcon="angular" (click)="navigateToExternal('https://angular.io')"></mat-icon>
      <mat-icon class="pwa" svgIcon="pwa" (click)="navigateToExternal('https://web.dev/what-are-pwas')"></mat-icon> 
      <mat-icon class="swanscript-logo" svgIcon="swanscript" (click)="navigateToExternal('https://swanscript.net')"></mat-icon>
      <mat-icon class="firebase" svgIcon="firebase" (click)="navigateToExternal('https://firebase.google.com')"></mat-icon>
    </div>
    <div class="info full-width" fxLayout="row" fxLayoutAlign="center center">Contact: support@swanscript.net</div>
  </div>

</div>