import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BannerService } from 'src/app/services/banner.service';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import auth from 'firebase/compat/app';
import { NotVerifiedPromptComponent } from 'src/app/components/not-verified-prompt/not-verified-prompt.component';
import { PassResetComponent } from 'src/app/components/pass-reset/pass-reset.component';
import { DOCUMENT } from '@angular/common';
import { StatsService } from 'src/app/services/stats.service';


@Component({
  selector: 'swan-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  confirmPassword: string;
  isSigningUp = false;
  error: boolean;
  returnUrl: string;
  publicAccess: boolean;

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private statsService: StatsService,
              private bannerService: BannerService,
              private dialog: MatDialog,
              @Inject(DOCUMENT) private document: Document) {
    this.publicAccess = environment.publicAccess;
    this.error = false;
    this.authService.logout(false);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.bannerService.setFooterOptions([]);
    this.bannerService.setBackUrl('');
  }

  ngOnInit() {
    if (this.publicAccess) {
      const loggedInBefore = localStorage.getItem('loggedInBefore');
      if (!loggedInBefore) {
        localStorage.setItem('loggedInBefore', 'yes')
      } 
    }
  }


  passwordValid(): boolean {
    if (this.confirmPassword) {
      return this.password === this.confirmPassword;
    } else {
      return true;
    }
  }

  passLengthCheck(): boolean {
    return (this.password) ? this.password.length >= 6 : false;
  }

  canContinue(): boolean {
    if (!this.isSigningUp) {
      return !!this.email && !!this.password;
    } else {
      // tslint:disable-next-line: max-line-length
      return !!this.email && !!this.password && !!this.confirmPassword && (this.password === this.confirmPassword) && this.passLengthCheck();
    }
  }

  navigateAbout() {
    this.router.navigate(['/about']);
  }

  support() {
    this.document.location.href = 'https://ko-fi.com/swanscript';
  }

  login() {
    if (this.canContinue) {
      this.statsService.incrementStat('attemptLogin');
      this.error = false;
      this.bannerService.loading.next(true);
      this.authService.emailLogin(this.email, this.password).then((credentials: auth.auth.UserCredential) => {
        this.bannerService.loading.next(false);
        if (credentials) {
          this.statsService.incrementStat('emailLogin');
          if (!(credentials.user.emailVerified)) {
            this.dialog.open(NotVerifiedPromptComponent, {data: credentials.user, autoFocus: false, width: '85vw', maxHeight: '85vh'});
          }
          this.router.navigate(['/app']);
        } else {
          this.password = '';
          this.error = true;
        }
      })
    }
  }

  resetPassword() {
    // tslint:disable-next-line: max-line-length
    this.dialog.open(PassResetComponent, {data: (this.email) ? this.email : '',disableClose: true, autoFocus: false, width: '85vw', maxHeight: '85vh'});
  }

  googleLogin() {
    this.statsService.incrementStat('attemptGoogleLogin');
    this.error = false;
    this.bannerService.loading.next(true);
    this.authService.googleLogin().then((credentials: auth.auth.UserCredential) => {
      this.bannerService.loading.next(false);
      if (credentials) {
        this.statsService.incrementStat('googleLogin');
        this.router.navigate(['/app']);
      } else {
        this.error = true;
      }
    })
  }

  facebookLogin() {
    this.statsService.incrementStat('attemptFacebookLogin');
    this.bannerService.loading.next(true);
    this.error = false;
    this.authService.facebookLogin().then((credentials: auth.auth.UserCredential) => {
      this.bannerService.loading.next(false);
      if (credentials) {
        this.statsService.incrementStat('facebookLogin');
        this.router.navigate(['/app']);
      } else {
        this.error = true;
      }
    })
  }

  signup() {
    if (this.canContinue) {
      this.statsService.incrementStat('attemptSignup');
      this.error = false;
      this.bannerService.loading.next(true);
      this.authService.emailSignup(this.email, this.password).then((credentials: auth.auth.UserCredential) => {
        if (credentials) {
          this.statsService.incrementStat('emailSignup');
          this.bannerService.loading.next(false);
          credentials.user.sendEmailVerification();
          this.router.navigate(['/app']);
        } else {
          this.bannerService.loading.next(false);
          this.error = true;
        }
      })
    }
  }
}
