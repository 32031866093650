import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feedback } from 'src/app/classes/feedback';
import { User } from 'src/app/classes/user';
import moment from 'moment';

@Component({
  selector: 'swan-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

  feedback: Feedback;
  feedbackTypes = ['Bug Report', 'Comment', 'Compliment', 'Feature Request', 'Other'];
  user: User;

  constructor(private ref: MatDialogRef<FeedbackFormComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.ref.disableClose = true;
    if (data) {
      this.user = data.user;
      this.feedback = new Feedback({userUqid: this.user.uid, type: this.feedbackTypes[0], submitDate: moment()});
    }
  }

  ngOnInit() {
  }

  isValid(): boolean {
    return this.feedback && !!this.feedback.message;
  }

  close() {
    this.ref.close();
  }

  done() {
    if (this.isValid()) {
      this.ref.close(this.feedback);
    }
  }

}
