import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'swan-update-prompt',
  templateUrl: './update-prompt.component.html',
  styleUrls: ['./update-prompt.component.scss']
})
export class UpdatePromptComponent implements OnInit {

  constructor(private ref: MatDialogRef<UpdatePromptComponent>, @Inject(MAT_DIALOG_DATA) data: any) { }

  ngOnInit() {
  }

  refresh() {
    this.ref.close(true);
  }

  dismiss() {
    this.ref.close();
  }

}
