import moment from 'moment';
import { trimIfExists } from '../utils/string-utils';
import { Role } from './role';

export class User {
  public email?: string;
  public groupIds?: string[];
  public joinDate?: moment.Moment;
  public lastLogin?: moment.Moment;
  public role?: string;
  public seenNews?: string[];
  public subscriptionUqid?: string;
  public uid?: string;
  public roleObj?: Role;

  constructor(user?: any) {
    this.email = (user && user.email) ? user.email : '';
    this.groupIds = (user && user.groupIds) ? user.groupIds : [];
    this.joinDate = (user && user.joinDate) ? moment(user.joinDate) : moment();
    this.lastLogin = (user && user.lastLogin) ? moment(user.lastLogin) : moment();
    this.role = (user && user.role) ? user.role : undefined;
    this.seenNews = (user && user.seenNews) ? user.seenNews : [];
    this.subscriptionUqid = (user && user.subscriptionUqid) ? user.subscriptionUqid : undefined;
    this.uid = (user && user.uid) ? user.uid : undefined;
    this.roleObj = (user && user.roleObj) ? new Role(user.roleObj) : undefined;
  }

  public simplify(): any {
    const ret: any = {
      email: trimIfExists(<string>this.email),
      groupIds: this.groupIds,
      seenNews: this.seenNews,
      joinDate: (this.joinDate) ? this.joinDate.toDate() : undefined,
      lastLogin: (this.lastLogin) ? this.lastLogin.toDate() : undefined,
      role: trimIfExists(<string>this.role),
      uid: trimIfExists(<string>this.uid),
    };
    if (this.subscriptionUqid) {
      ret.subscriptionUqid = trimIfExists(<string>this.subscriptionUqid);
    }
    return ret;
  }

  public isAdmin(): boolean {
    return !!this.roleObj && this.roleObj.name === 'Admin';
  }

}
