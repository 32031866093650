import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Email } from 'src/app/classes/email';
import { User } from 'src/app/classes/user';

@Component({
  selector: 'swan-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  email: Email;

  constructor(private ref: MatDialogRef<ContactUsComponent>,
             @Inject(MAT_DIALOG_DATA) data: User) {
    this.email = new Email();
    this.email.from = data.email;
  }

  ngOnInit(): void {
  }

  isValid(): boolean {
    return !!this.email.subject && !!this.email.message;
  }

  close() {
    this.ref.close();
  }

  send() {
    if (this.isValid) {
      this.ref.close(this.email);
    }
  }

}
