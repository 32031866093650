import { trimIfExists } from '../utils/string-utils';

export class TripItem {
  public category?: string;
  public checked?: boolean;
  public dependencyRatio?: number;
  public lastMinute?: boolean;
  public name?: string;
  public numberExtra?: number;
  public parentUqid?: string;
  public uqid?: string;

  constructor(item?: any) {
    this.category = (item && item.category) ? item.category : undefined;
    this.checked = (item && item.checked) ? item.checked : false;
    this.dependencyRatio = (item && item.dependencyRatio) ? item.dependencyRatio : 0;
    this.lastMinute = (item && item.lastMinute) ? item.lastMinute : false;
    this.name = (item && item.name) ? item.name : '';
    this.numberExtra = (item && item.numberExtra) ? item.numberExtra : 0;
    this.parentUqid = (item && item.parentUqid) ? item.parentUqid : undefined;
    this.uqid = (item && item.uqid) ? item.uqid : undefined;
  }

  public simplify(): any {
    return {
      category: trimIfExists(this.category),
      checked: this.checked,
      dependencyRatio: this.dependencyRatio,
      lastMinute: this.lastMinute,
      name: trimIfExists(this.name),
      numberExtra: this.numberExtra,
      parentUqid: trimIfExists(this.parentUqid),
      uqid: trimIfExists(this.uqid)
    };
  }

  public updateItem(item: TripItem) {
    this.category = item.category;
    this.checked = item.checked;
    this.dependencyRatio = item.dependencyRatio;
    this.lastMinute = item.lastMinute;
    this.name = item.name;
    this.numberExtra = item.numberExtra;
  }
}
