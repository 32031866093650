import { trimIfExists } from '../utils/string-utils';

export class Group {
  public name?: string;
  public uqid?: string;

  constructor(group?: any) {
    this.name = (group && group.name) ? group.name : '';
    this.uqid = (group && group.uqid) ? group.uqid : undefined;
  }

  public simplify(): any {
    return {
      name: trimIfExists(this.name),
      uqid: trimIfExists(this.uqid),
    };
  }
}
