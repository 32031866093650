import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ShoppingService } from 'src/app/services/shopping.service';
import { ShoppingList } from 'src/app/classes/shopping-list';
import moment from 'moment';
import { User } from 'src/app/classes/user';
import { DataLevel } from 'src/app/classes/data-level';
import { Group } from 'src/app/classes/group';
import { BannerService } from 'src/app/services/banner.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreCategoryContainer } from 'src/app/services/template.service';

@Component({
  selector: 'swan-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.scss']
})
export class EditListComponent implements OnInit {

  list: ShoppingList;
  shoppingDate: Date;
  today = new Date();
  user: User;
  access: 'group' | 'private';
  groups: Group[] = [];
  storeCategoryContainers: StoreCategoryContainer[] = [];

  constructor(private ref: MatDialogRef<EditListComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private shoppingService: ShoppingService,
              private globalService: GlobalService,
              private dialog: MatDialog,
              private bannerService: BannerService) {
    ref.disableClose = true;
    if (data) {
      this.user = data.user;
      this.list = data.list;
      this.groups = data.groups;
      if (this.list.shoppingDate) {
        this.shoppingDate = new Date(this.list.shoppingDate.format());
      }
    }
  }

  ngOnInit() {
    this.globalService.getStoreCategories(this.user).then(() => {
      this.globalService.storeCategoryContainers.subscribe((containers: StoreCategoryContainer[]) => {
        this.storeCategoryContainers = containers;
      });
    });
  }

  renameLocation(location: string, index: number) {
    this.list.locations[index] = location;
    this.list.items.forEach((value, key) => {
      for (let i = 0; i < value.length; i++) {
        if (!this.list.locations.includes(value[i].location)) {
          value[i].location = location;
        }
      }
    });
    this.updateList();
  }

  deleteLocation(location: string) {
    this.list.locations.splice(this.list.locations.indexOf(location), 1);
    this.list.items.forEach((value, key) => {
      const newVals = value.filter(v => v.location !== location);
      value = newVals;
    });
    this.updateList();
  }

  addLocation() {
    this.list.locations.push('New Location');
    this.updateList();
  }

  updateShoppingDate() {
    this.list.shoppingDate = moment(this.shoppingDate);
    this.updateList();
  }

  updateList() {
    this.shoppingService.updateList(DataLevel.fromExprssion(this.list.accessId === this.user.uid), this.list.accessId, this.list)
    .then()
  }

  close() {
    this.ref.close();
  }

  delete() {
    this.shoppingService.deleteList(DataLevel.fromExprssion(this.list.accessId === this.user.uid), this.list.accessId, this.list)
    .then()
    this.close();
  }

  getGroupsWithoutCurrent(): Group[] {
    const index = this.groups.findIndex(g => g.uqid === this.list.accessId);
    if (index === -1) {
      return this.groups;
    }
    return this.groups.filter(g => g.uqid !== this.list.accessId).concat(new Group({name: 'Private', uqid: this.user.uid}));
  }

}
