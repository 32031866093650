import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Trip } from '../classes/trip';
import { DataLevel } from '../classes/data-level';
import { User } from '../classes/user';
import { TripItem } from '../classes/trip-item';

const TRIP = 'trip';

@Injectable({
  providedIn: 'root'
})
export class TravelService {
  private _db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this._db = db;
  }

  private getStartingRef(from: DataLevel, id: string): AngularFirestoreDocument {
    if (from === DataLevel.group) {
      return this._db.collection('group-data').doc(id);
    } else {
      return this._db.collection('user-data').doc(id);
    }
  }

  public getAllTrips(user: User): Observable<Trip[]> {
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<Trip>(TRIP).valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<Trip>(TRIP).valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
      map(ret => {
        if (Array.isArray(ret)) {
          return ret.map((t: any) => {
            if (t.endDate && (t.endDate as FireTimeStamp).seconds) {
              t.endDate = new Date((t.endDate as FireTimeStamp).seconds * 1000);
            }
            if (t.startDate && (t.startDate as FireTimeStamp).seconds) {
              t.startDate = new Date((t.startDate as FireTimeStamp).seconds * 1000);
            }
            return new Trip(t);
          });
        } else {
          return [];
        }
      })
    );
  }

  public getTripByUqid(from: DataLevel, id: string, uqid: string): Observable<Trip> {
    return this.getStartingRef(from, id).collection<Trip>(TRIP)
      .doc(uqid)
      .valueChanges()
      .pipe(
        map((ret: any) => {
          ret.endDate = new Date((ret.endDate as FireTimeStamp).seconds * 1000);
          ret.startDate = new Date((ret.startDate as FireTimeStamp).seconds * 1000);
          return new Trip(<Trip>ret);
        })
      );
  }

  public updateTrip(from: DataLevel, id: string, trip: Trip): Promise<void> {
    let isNew = false;
    if (!trip.uqid) {
      trip.uqid = this._db.createId();
      isNew = true;
    }
    trip.items.forEach((value, key) => {
      for (let i = 0; i < value.length; i++) {
        value[i].parentUqid = trip.uqid;
        if (!value[i].uqid) {
          value[i].uqid = this._db.createId();
        }
      }
    });
    if (isNew) {
      return this.getStartingRef(from, id).collection(TRIP).doc(trip.uqid).set(trip.simplify());
    } else {
      return this.getStartingRef(from, id).collection(TRIP).doc(trip.uqid).update(trip.simplify());
    }
  }

  public copyTrip(to: DataLevel, id: string, trip: Trip, categoryMap: Map<string, string>): Promise<void> {
    const newTrip = new Trip();
    newTrip.updateTrip(trip);
    newTrip.accessId = id;
    newTrip.uqid = this._db.createId();
    const tripItems = newTrip.getItemsAsArray().slice();
    newTrip.items = new Map<string, TripItem[]>();
    for (let i = 0; i < tripItems.length; i++) {
      tripItems[i].parentUqid = newTrip.uqid;
      tripItems[i].category = categoryMap.get(tripItems[i].category);
      const key = `${tripItems[i].category}`;
      if (!newTrip.items.get(key)) {
        newTrip.items.set(key, []);
      }
      newTrip.items.get(key).push(tripItems[i]);
    }
    return this.getStartingRef(to, id).collection(TRIP).doc(newTrip.uqid).set(newTrip.simplify());
  }

  public deleteTrip(from: DataLevel, id: string, trip: Trip): Promise<void> {
    return this.getStartingRef(from, id).collection(TRIP).doc(trip.uqid).delete();
  }
}

interface FireTimeStamp {
  seconds?: number;
  nanoseconds?: number;
}
