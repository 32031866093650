export class Priority {
    static readonly Low: Priority = new Priority('Low');
    static readonly Medium: Priority = new Priority('Medium');
    static readonly High: Priority = new Priority('High');

    public readonly name: string;

    private constructor(name: string) {
      this.name = name;
    }

    static forName(name: string | Priority) {
      const val = (name instanceof Priority) ? name.toString() : name;
      if (val === 'Low') { return Priority.Low; }
      if (val === 'Medium') { return Priority.Medium; }
      if (val === 'High') { return Priority.High; }
      throw new Error('Unexpected value for Priority:' + val);
    }

    static values(): Priority[] {
      return [
        Priority.Low,
        Priority.Medium,
        Priority.High
      ];
    }

    toJSON(): string {
      return this.name;
    }

    toString(): string {
      return this.name;
    }
}
