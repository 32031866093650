<div class="home-container background-bg">
  <div class="full-width full-height" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div class="full-width" style="padding: 8px 12px 0 24px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon color="accent">inbox</mat-icon>
      <div class="section-title">Backlog</div>
      <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="accent" (click)="addTask()">+ Add Task</button>
      </div>
    </div>
    <div *ngIf="!tasks.length" class="wrapper full-width" fxLayout="column" fxLayoutAlign="start center">
      <div class="empty full-width pointer" fxLayout="column" fxLayoutAlign="start center">
        <div>None Found</div>
      </div>
    </div>
    <div *ngIf="tasks.length" cdkDropList class="wrapper task-list full-width" fxLayout="column" fxLayoutAlign="start start" (cdkDropListDropped)="taskDrop($event)">
      <div *ngFor="let task of tasks;" class="full-width task background-bg pointer foreground"
            (click)="editTask(task)"
            cdkDrag
            cdkDragLockAxis="y"
            cdkDragStartDelay="150">
        <div class="task-header"  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
          <div fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="task-name accent-c full-width overflow-ellipsis">{{ task.name }} <span class="group-label">{{groupLabelMap[task.accessId]}}</span></div>
            <div *ngIf="task.description" class="task-description full-width overflow-ellipsis">{{ task.description }}</div>
          </div>
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
        </div>

        <div class="priority-mark-container left" fxLayout="row" fxLayoutAlign="center center">
          <div class="priority-mark" [ngClass]="{'low': task.priority == 'Low', 'medium': task.priority == 'Medium', 'high': task.priority == 'High'}"></div>
        </div>
        <div class="task-details card-footer">
          <div class="full-width full-height" fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" (click)="addComment(task)">
              <mat-icon class="pointer" color="accent">comment</mat-icon>
              <div class="light-font-small">{{ task.comments.length }}</div>
            </div>
            <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
              <div class="status-toggle" fxLayout="row" fxLayoutAlign="start center">
                <div
                  class="status"
                  (click)="$event.stopPropagation(); updateSelectedTaskStatus(task, WorkflowStatus.ToDo)"
                  [ngClass]="{'accent-bg active': task.status == WorkflowStatus.ToDo, 'accent-bg-200 passed': task.status != WorkflowStatus.ToDo}"
                >To Do</div>
                <div
                  class="status"
                  (click)="$event.stopPropagation(); updateSelectedTaskStatus(task, WorkflowStatus.InProgress)"
                  [ngClass]="{'accent-bg active': task.status == WorkflowStatus.InProgress, 'accent-bg-200 passed': task.status == WorkflowStatus.Done}"
                >In Progress</div>
                <div
                  class="status"
                  (click)="$event.stopPropagation(); updateSelectedTaskStatus(task, WorkflowStatus.Done)"
                  [ngClass]="{'accent-bg active': task.status == WorkflowStatus.Done}"
                >Done</div>
              </div>
            </div>
          </div>
        </div>
        <div class="task-drag-placeholder dotted-accent-border accent-bg-100" *cdkDragPlaceholder></div>
      </div>
    </div>
    <div class="full-width" style="padding: 8px 12px 0 24px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon color="accent">shopping_cart</mat-icon>
      <div class="section-title">Shopping</div>
      <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="accent" (click)="addList()">+ Add List</button>
      </div>
    </div>
    <div *ngIf="!lists.length" class="wrapper full-width" fxLayout="column" fxLayoutAlign="start center">
      <div class="empty full-width pointer" fxLayout="column" fxLayoutAlign="start center">
        <div>None Found</div>
      </div>
    </div>
    <div *ngIf="lists.length" class="wrapper" fxLayout="column" fxLayoutGap="16px">
      <div class="full-width card pointer foreground"
          (click)="openList(list);"
          *ngFor="let list of lists; let i = index">
        <div *ngIf="list.isDone" class="done-overlay">
          <div class="full-width full-height" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="check-icon">check_circle_outline</mat-icon>
          </div>
        </div>
        <div fxLayout="column" fxLayout="start center" fxLayoutGap="10px">
          <div class="card-header full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" [ngClass]="{'shadow': list.notes}">
            <div fxFlex="grow" class="name accent-c overflow-ellipsis">{{ list.name }} <span class="group-label">{{groupLabelMap[list.accessId]}}</span></div>
            <div class="date" *ngIf="list.shoppingDate">{{ list.shoppingDate.format('ddd, MMM Do') }}</div>
          </div>
          <div *ngIf="list.notes" class="card-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div  class="full-width" style="padding: 5px;" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div class="full-width" style="font-size: 12px;">Notes:</div>
              <div class="full-width light-font-small" style="white-space: pre-wrap;">{{ list.notes }}</div>
            </div>
          </div>
          <div *ngIf="list.totalItems > 0" class="full-width card-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
              <div class="progress primary-bg" *ngIf="!list.noProgress" [ngClass]="{'full': list.isDone}" [fxFlex]="progressWidth(list.totalItems, list.numberChecked)"></div>
              <div class="progress-base" *ngIf="!list.isDone"  [ngClass]="{'full': list.noProgress}" [fxFlex]="baseProgressWidth(list.totalItems, list.numberChecked)"></div>
            </div>
            <div fxFlex="55px" class="light-font-small" style="text-align: center;">{{ list.numberCheckedString }}</div>
            <mat-icon color="primary">chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="full-width" style="padding: 8px 12px 0 24px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon color="accent">luggage</mat-icon>
      <div class="section-title">Travel</div>
      <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="accent" (click)="addTrip()">+ Add Trip</button>
      </div>
    </div>
    <div *ngIf="!trips.length" class="wrapper full-width" fxLayout="column" fxLayoutAlign="start center">
      <div class="empty full-width pointer" fxLayout="column" fxLayoutAlign="start center">
        <div>None Found</div>
      </div>
    </div>
    <div *ngIf="trips.length" class="wrapper" fxLayout="column" fxLayoutGap="16px">
      <div class="full-width card pointer foreground"
          (click)="openTrip(trip);"
          *ngFor="let trip of trips; let i = index">
        <div *ngIf="trip.isDone" class="done-overlay">
          <div class="full-width full-height" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="check-icon primary-c">check_circle_outline</mat-icon>
          </div>
        </div>
        <div fxLayout="column" fxLayout="start center" fxLayoutGap="10px">
          <div class="card-header full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxFlex="grow" class="name accent-c overflow-ellipsis">{{ trip.name }} <span class="group-label">{{groupLabelMap[trip.accessId]}}</span></div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
              <div class="date">{{ trip.startDate.format('M/D') }}</div>
              <div class="date">-</div>
              <div class="date">{{ trip.endDate.format('M/D') }}</div>
            </div>
          </div>
          <div *ngIf="trip.notes" class="card-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div  class="full-width" style="padding: 5px;" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div class="full-width" style="font-size: 12px;">Notes:</div>
              <div class="full-width light-font-small" style="white-space: pre-wrap;">{{ trip.notes }}</div>
            </div>
          </div>
          <div *ngIf="trip.totalItems > 0" class="full-width card-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
              <div class="progress primary-bg" *ngIf="!trip.noProgress" [ngClass]="{'full': trip.isDone}" [fxFlex]="progressWidth(trip.totalItems, trip.numberChecked)"></div>
              <div class="progress-base" *ngIf="!trip.isDone"  [ngClass]="{'full': trip.noProgress}" [fxFlex]="baseProgressWidth(trip.totalItems, trip.numberChecked)"></div>
            </div>
            <div fxFlex="55px" class="light-font-small" style="text-align: center;">{{ trip.numberCheckedString }}</div>
            <mat-icon color="primary">chevron_right</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="full-width" style="padding: 8px 12px 0 24px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon color="accent">engineering</mat-icon>
      <div class="section-title">Projects</div>
      <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="accent" (click)="addProject()">+ Add Project</button>
      </div>
    </div>
    <div *ngIf="!projects.length" class="wrapper full-width" fxLayout="column" fxLayoutAlign="start center">
      <div class="empty full-width pointer" fxLayout="column" fxLayoutAlign="start center">
        <div>None Found</div>
      </div>
    </div>
    <div *ngIf="projects.length" class="wrapper" fxLayout="column" fxLayoutGap="16px">
      <div class="full-width card pointer foreground"
          (click)="openProject(project);"
          *ngFor="let project of projects; let i = index">
        <div *ngIf="projectIsDone(project)" class="done-overlay">
          <div class="full-width full-height" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon class="check-icon">check_circle_outline</mat-icon>
          </div>
        </div>
        <div fxLayout="column" fxLayout="start center" fxLayoutGap="10px">
          <div class="card-header full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxFlex="grow" class="project-name accent-c overflow-ellipsis">{{ project.name }} <span class="group-label">{{groupLabelMap[project.accessId]}}</span></div>
            <div class="date" *ngIf="project.dueDate">{{ displayDueDate(project)}}</div>
          </div>
          <div class="card-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div *ngIf="project.description" class="full-width" style="padding: 5px;" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div class="full-width" style="font-size: 12px;">Description:</div>
              <div style="white-space: pre-wrap;" class=" full-width light-font-small">{{ project.description }}</div>
            </div>
          </div>
          <div class="full-width card-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
            <swan-project-progress class="full-width" [tasks]="project.tasks"></swan-project-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
