<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow" class="modal-title">Password Reset</div>
    </div>
    <div class="modal-content full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-form-field class="full-width" color="accent" appearance="outline">
            <mat-label>Please enter your account email</mat-label>
            <input matInput [(ngModel)]="email" type="text">
        </mat-form-field>
    </div>
    <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
            <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
                <div class="modal-footer-text" [ngClass]="{'disabled': !isValid()}">Send Reset Email</div>
                <mat-icon class="modal-footer-icon" [ngClass]="{'disabled': !isValid()}">send</mat-icon>
            </div>
        </div>
    </div>
</div>