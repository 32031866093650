import { Injectable, OnDestroy } from '@angular/core';
import { ShoppingList } from '../classes/shopping-list';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Trip } from '../classes/trip';
import { Recipe } from '../classes/recipe';
import { PackingCategoryTemplate } from '../classes/packing-category-template';
import { ShoppingService } from './shopping.service';
import { TravelService } from './travel.service';
import { RecipeService } from './recipe.service';
import { TemplateService, StoreCategoryContainer } from './template.service';
import { User } from '../classes/user';
import { TaskService } from './task.service';
import { Task } from '../classes/task';
import { Project } from '../classes/project';
import { NgxCloudScriptureService, DailyVerse, DailyReading } from 'ngx-cloud-scripture';

@Injectable({
  providedIn: 'root'
})
export class GlobalService implements OnDestroy {

  public shoppingLists: BehaviorSubject<ShoppingList[]> = new BehaviorSubject<ShoppingList[]>([]);
  public trips: BehaviorSubject<Trip[]> = new BehaviorSubject<Trip[]>([]);
  public recipes: BehaviorSubject<Recipe[]> = new BehaviorSubject<Recipe[]>([]);
  public storeCategoryContainers: BehaviorSubject<StoreCategoryContainer[]> = new BehaviorSubject<StoreCategoryContainer[]>([]);
  public packingTemplates: BehaviorSubject<PackingCategoryTemplate[]> = new BehaviorSubject<PackingCategoryTemplate[]>([]);
  public tasks: BehaviorSubject<Task[]> = new BehaviorSubject<Task[]>([]);
  public projects: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
  public dailyVerse: BehaviorSubject<DailyVerse> = new BehaviorSubject<DailyVerse>(undefined);
  public dailyReading: BehaviorSubject<DailyReading[]> = new BehaviorSubject<DailyReading[]>([]);

  private subscriptions: Map<string, Subscription> = new Map<string, Subscription>();

  constructor(
    private shoppingService: ShoppingService,
    private travelService: TravelService,
    private recipeService: RecipeService,
    private templateService: TemplateService,
    private cloudScriptureService: NgxCloudScriptureService,
    private taskService: TaskService
    ) {
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  destroySubscriptions() {
    this.subscriptions.forEach((value) => {
      if (value) {
        value.unsubscribe();
      }
    });
    this.subscriptions = new Map<string, Subscription>();
    this.shoppingLists = new BehaviorSubject<ShoppingList[]>([]);
    this.trips = new BehaviorSubject<Trip[]>([]);
    this.recipes = new BehaviorSubject<Recipe[]>([]);
    this.storeCategoryContainers = new BehaviorSubject<StoreCategoryContainer[]>([]);
    this.packingTemplates = new BehaviorSubject<PackingCategoryTemplate[]>([]);
    this.tasks = new BehaviorSubject<Task[]>([]);
    this.projects = new BehaviorSubject<Project[]>([]);
    this.dailyVerse = new BehaviorSubject<DailyVerse>(undefined);
    this.dailyReading = new BehaviorSubject<DailyReading[]>([]);
  }

  getShoppingLists(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('shopping-lists')) {
        const sub = this.shoppingService.getAllLists(user).subscribe((lists) => {
          this.shoppingLists.next(lists);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('shopping-lists', sub);
      } else {
        resolve();
      }
    });
  }

  getTrips(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('trips')) {
        const sub = this.travelService.getAllTrips(user).subscribe((trips) => {
          this.trips.next(trips);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('trips', sub);
      } else {
        resolve();
      }
    });
  }

  getRecipes(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('recipes')) {
        const sub = this.recipeService.getAllRecipes(user).subscribe((recipes) => {
          this.recipes.next(recipes);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('recipes', sub);
      } else {
        resolve();
      }
    });
  }

  getPackingTemplates(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('packing-templates')) {
        const sub = this.templateService.getAllPackingTemplates(user).subscribe((templates) => {
          this.packingTemplates.next(templates);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('packing-templates', sub);
      } else {
        resolve();
      }
    });
  }

  getStoreCategories(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('store-categories')) {
        const sub = this.templateService.getAllStoreCategories(user).subscribe((containers) => {
          this.storeCategoryContainers.next(containers);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('store-categories', sub);
      } else {
        resolve();
      }
    });
  }

  getTasks(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('tasks')) {
        const sub = this.taskService.getAllTasks(user).subscribe((tasks) => {
          this.tasks.next(tasks);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('tasks', sub);
      } else {
        resolve();
      }
    });
  }

  getProjects(user: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('projects')) {
        const sub = this.taskService.getAllProjects(user).subscribe((projects) => {
          this.projects.next(projects);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('projects', sub);
      } else {
        resolve();
      }
    });
  }

  getDailyVerse(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('daily-verse')) {
        const sub = this.cloudScriptureService.getDailyVerse('NKJV').subscribe((verse) => {
          this.dailyVerse.next(verse);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('daily-verse', sub);
      } else {
        resolve();
      }
    });
  }

  getDailyReading(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.subscriptions.get('daily-readings')) {
        const sub = this.cloudScriptureService.getDailyReading('NKJV').subscribe((readings) => {
          this.dailyReading.next(readings);
          resolve();
        }, (error) => {
          reject(error);
        });
        this.subscriptions.set('daily-readings', sub);
      } else {
        resolve();
      }
    });
  }
}
