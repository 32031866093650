import { trigger, state, style, transition, stagger, animate, keyframes, query } from '@angular/animations';

export const shake = trigger('shake', [
  state('inactive', style({ opacity: 1 })),
  state('active', style({ opacity: 1 })),
  transition('* => *', [
      query('.editting-element', stagger('125ms', [
        animate('0.35s', keyframes([
            style({transform: 'translate3d(-1px, 0, 0)'}),
            style({transform: 'translate3d(-2px, 0, 0)'}),
            style({transform: 'translate3d(-1px, 0, 0)'}),
            style({transform: 'translate3d(0, 0, 0)'}),
            style({transform: 'translate3d(1px, 0, 0)'}),
            style({transform: 'translate3d(2px, 0, 0)'}),
            style({transform: 'translate3d(1px, 0, 0)'}),
            style({transform: 'translate3d(0, 0, 0)'}),
        ]))]), { optional: true })
  ])
]);

export const slideInOut = trigger('slideInOut', [
  transition('* => void', [
      style({transform: 'translateY(0)'}),
      animate('0.25s ease-in', style({transform: 'translateY(100%)'}))
  ]),
  transition('void => *', [
      style({transform: 'translateY(100%)'}),
      animate('0.25s ease-out', style({transform: 'translateY(0)'}))
  ]),
]);

export const shrink = trigger('shrink', [
  transition('* => void', [
      style({height: '*', opacity: '1'}),
      animate('0.2s ease-out', style({height: '0', opacity: '0'}))
  ])
]);

export const growShrink = trigger('growShrink', [
  transition('* => void', [
      style({height: '*', opacity: '1'}),
      animate('0.75s ease-in-out', style({height: '0', opacity: '0'}))
  ]),
  transition('void => *', [
    style({height: '0', opacity: '0'}),
    animate('0.75s ease-in-out', style({height: '*', opacity: '1'}))
  ])
]);
