import { Component, OnInit, Inject } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DailyVerse, DailyReading } from 'ngx-cloud-scripture';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'swan-scripture',
  templateUrl: './scripture.component.html',
  styleUrls: ['./scripture.component.scss']
})
export class ScriptureComponent implements OnInit {

  verse: DailyVerse;
  readings: DailyReading[];
  loadingVerse = false;
  loadingReading = false;
  now: moment.Moment = moment();

  get isLoading(): boolean {
    return this.loadingVerse || this.loadingReading;
  }

  constructor(private globalService: GlobalService,
              @Inject(DOCUMENT) private document: Document) {
    this.loadingVerse = true;
    this.loadingReading = true;
    this.globalService.getDailyVerse().then(() => {
      this.globalService.dailyVerse.subscribe((verse) => {
        this.loadingVerse = false;
        this.verse = verse;
      });
    });

    this.globalService.getDailyReading().then(() => {
      this.globalService.dailyReading.subscribe((readings) => {
        this.loadingReading = false;
        this.readings = readings;
      });
    })
  }

  ngOnInit(): void {
  }

  goToFull() {
    this.document.location.href = 'https://cloudscripture.com';
  }

}
