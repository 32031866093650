<div style="padding: 20px" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
  <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon class="offline-icon" color="accent">sync_problem</mat-icon>
  </div>
  <div class="bold-font-dark">Your device is offline!</div>
  <div style="color: #a6a6a6;">You may not be able to receive or send data</div>
  <div class="full-width" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-button (click)="dismiss()">Dismiss</button>
  </div>
</div>
