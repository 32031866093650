<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Feedback</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div class="modal-content full-width" *ngIf="feedback" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>Type of Feedback</mat-label>
      <mat-select [(ngModel)]="feedback.type">
        <mat-option *ngFor="let type of feedbackTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>Feedback Message</mat-label>
      <textarea matInput rows="5" [(ngModel)]="feedback.message"></textarea>
    </mat-form-field>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="done()">
        <div class="modal-footer-text" [ngClass]="{'disabled': !isValid()}">Submit</div>
        <mat-icon class="modal-footer-icon" [ngClass]="{'disabled': !isValid()}">check</mat-icon>
      </div>
    </div>
  </div>
</div>
