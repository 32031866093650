import { Component, OnInit, OnDestroy } from '@angular/core';
import User from 'firebase/compat/app';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { User as NormalUser } from 'src/app/classes/user';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BannerService } from 'src/app/services/banner.service';
import { Subject, Subscription } from 'rxjs';
import { DeleteUserComponent } from '../delete-user/delete-user.component';
import auth from 'firebase/compat/app';
import { Router } from '@angular/router';

@Component({
  selector: 'swan-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent implements OnInit, OnDestroy {

  user: NormalUser;
  firebaseUser: User.User;
  changeEmail = false;
  changePassword = false;
  updatedEmail: string;

  newPassword: string;
  newPasswordConfirmed: string;

  saveChangesPass: string;

  ready: Subject<void>;
  emailChangeSub: Subject<void>;
  passChangeSub: Subject<void>;

  provider: string;

  private disposables: Subscription[] = [];

  constructor(private authService: AuthService,
              private dialog: MatDialog,
              private popup: MatSnackBar,
              private router: Router,
              private bannerService: BannerService) {
    this.bannerService.loading.next(true);
    this.bannerService.setBackUrl('Home');
    this.authService.user.subscribe((u) => {
      this.user = u;
      this.user.roleObj = this.authService.myRole.value;
      this.bannerService.loading.next(false);
    });
    this.authService.getFirebaseUser().subscribe((u) => {
      this.firebaseUser = u;
      this.updatedEmail = this.firebaseUser.email;
      if (this.firebaseUser.providerData.length) {
        this.provider = this.firebaseUser.providerData[0].providerId;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.dispose();
  }

  canSaveChanges(): boolean {
    const c1 = (this.changeEmail) ? !!this.updatedEmail : true;
    // tslint:disable-next-line: max-line-length
    const c2 = (this.changePassword) ? (!!this.newPassword && !!this.newPasswordConfirmed && this.passwordValid() && this.passLengthCheck()) : true;
    const c3 = !!this.saveChangesPass;

    return c1 && c2 && c3;
  }

  dispose() {
    this.disposables.forEach((disposable) => {
      disposable.unsubscribe();
    });
    this.disposables = [];
  }

  reset() {
    this.changeEmail = false;
    this.changePassword = false;
    this.newPassword = '';
    this.newPasswordConfirmed = '';
    this.saveChangesPass = '';
  }

  delete() {
    // tslint:disable-next-line: max-line-length
    this.dialog.open(DeleteUserComponent, {data: {firebaseUser: this.firebaseUser, user: this.user}, autoFocus: false, width: '85vw', maxHeight: '85vh'});
  }

  cancelMembership() {

  }

  passLengthCheck(): boolean {
    return (this.newPassword) ? this.newPassword.length >= 6 : false;
  }

  authenticateForChanges() {
    const authCredentials = auth.auth.EmailAuthProvider.credential(this.firebaseUser.email, this.saveChangesPass);
    this.firebaseUser.reauthenticateWithCredential(authCredentials).then(() => {
      this.saveChanges();
    })
  }

  saveChanges() {
    this.bannerService.loading.next(true);
    this.dispose();
    this.ready = new Subject();
    let doneCount = 0;
    const subscribeTo: Subject<void>[] = [];
    if (this.changeEmail) {
      this.emailChangeSub = new Subject();
      subscribeTo.push(this.emailChangeSub);
    }
    if (this.changePassword) {
      this.passChangeSub = new Subject();
      subscribeTo.push(this.passChangeSub);
    }
    const checkIsReady = () => {
      doneCount++;

      if (doneCount === subscribeTo.length) {
        this.ready.complete();
      }
    }

    for (const item of subscribeTo) {
      this.disposables.push(
        item.subscribe({
          next: undefined,
          error: undefined,
          complete: checkIsReady
        })
      );
    }

    this.ready.subscribe({
      next: undefined,
      error: undefined,
      complete: () => {
        this.bannerService.loading.next(false);
        let popupText = 'Changes Saved!';
        if (this.changeEmail) {
          popupText += ' Verification email sent!';
        }
        if (this.changePassword) {
          popupText += ' New password now in effect!';
        }
        this.popup.open(popupText, 'Dismiss', {duration: 10000, panelClass: ['snackbar-success']});
        this.reset();
      }
    });

    if (this.changeEmail) {
      this.firebaseUser.updateEmail(this.updatedEmail).then(() => {
        this.firebaseUser.sendEmailVerification().then(() => {
          this.emailChangeSub.complete();
        })
      })
    }
    if (this.changePassword) {
      this.firebaseUser.updatePassword(this.newPassword).then(() => {
        this.passChangeSub.complete();
      })
    }
  }

  toggleEditEmail() {
    if (this.changeEmail) {
      this.changeEmail = false;
      this.updatedEmail = this.firebaseUser.email;
    } else {
      this.changeEmail = true;
    }
  }

  toggleChangePassword() {
    if (this.changePassword) {
      this.changePassword = false;
      this.newPassword = '';
      this.newPasswordConfirmed = '';
    } else {
      this.changePassword = true;
    }
  }

  passwordValid(): boolean {
    if (this.newPasswordConfirmed) {
      return this.newPassword === this.newPasswordConfirmed;
    } else {
      return true;
    }
  }

  // get displayMembership(): string {
  //   if (this.user && this.user.hasGoldPerks()) {
  //     return 'Gold';
  //   }
  //   if (this.user && this.user.hasSilverPerks()) {
  //     return 'Silver';
  //   }
  //   if (this.user) {
  //     return 'Basic';
  //   }
  //   return '';
  // }

}
