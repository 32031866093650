import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import auth from 'firebase/compat/app';
import User from 'firebase/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/compat/firestore';

import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, take, map, first } from 'rxjs/operators';
import { User as NormalUser } from 'src/app/classes/user';
import { Group } from 'src/app/classes/group';
import { Invite } from 'src/app/classes/invite';
import { environment } from '../../../../environments/environment';
import { TemplateService } from 'src/app/services/template.service';
import { DataLevel } from 'src/app/classes/data-level';
import { GlobalService } from 'src/app/services/global.service';
import { FireTimeStamp } from 'src/app/services/shopping.service';
import { Role } from 'src/app/classes/role';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<NormalUser>;
  myRole: BehaviorSubject<Role> = new BehaviorSubject<Role>(new Role({name: 'Basic', uqid: environment.defaultRole}));
  defaultRole: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private templateService: TemplateService,
    private globalService: GlobalService
  ) {
      this.defaultRole = environment.defaultRole;
      //// Get auth data, then get firestore user document || null
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc<NormalUser>(`user/${user.uid}`).valueChanges()
              .pipe(
                map((u: any) => {
                  if (u) {
                    if (u.joinDate && (u.joinDate as FireTimeStamp).seconds) {
                      u.joinDate = new Date((u.joinDate as FireTimeStamp).seconds * 1000);
                    }
                    if (u.lastLogin && (u.lastLogin as FireTimeStamp).seconds) {
                      u.lastLogin = new Date((u.lastLogin as FireTimeStamp).seconds * 1000);
                    }
                  }
                  return new NormalUser(u);
                })
              );
          } else {
            return of(null);
          }
        })
      );
  }

  getFirebaseUser(): Observable<User.User> {
    return this.afAuth.authState;
  }

  emailLogin(email: string, password: string): Promise<auth.auth.UserCredential> {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((credentials: auth.auth.UserCredential) => {
        this.updateUserData(credentials.user);
        return credentials;
      })
      .catch(error => null);
  }

  emailSignup(email: string, password: string): Promise<auth.auth.UserCredential> {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((credentials) => {
        this.intitializeUser(credentials.user);
        return credentials;
      })
      .catch(error => null);
  }

  googleLogin(): Promise<auth.auth.UserCredential> {
    const provider = new auth.auth.GoogleAuthProvider();
    return this.afAuth.signInWithPopup(provider)
      .then((credentials: auth.auth.UserCredential) => {
        if (credentials.additionalUserInfo.isNewUser) {
          this.intitializeUser(credentials.user);
        } else {
          this.updateUserData(credentials.user);
        }
        return credentials;
      })
      .catch(error => null);
  }

  facebookLogin(): Promise<auth.auth.UserCredential> {
    const provider = new auth.auth.FacebookAuthProvider();
    return this.afAuth.signInWithPopup(provider)
      .then((credentials: auth.auth.UserCredential) => {
        if (credentials.additionalUserInfo.isNewUser) {
          this.intitializeUser(credentials.user);
        } else {
          this.updateUserData(credentials.user);
        }
        return credentials;
      })
      .catch(error => null);
  }

  getRoleFromId(roleUqid: string): Observable<Role> {
    return this.afs.collection<Role>('roles', (ref) => ref.where('uqid', '==', roleUqid).limit(1)).valueChanges()
      .pipe(
        take(1),
        map((ret) => {
          return (Array.isArray(ret) && ret.length) ? new Role(ret[0]) : new Role({name: 'Basic', uqid: environment.defaultRole});
        })
      );
  }

  getRoleFromName(name: string): Observable<Role> {
    return this.afs.collection<Role>('roles', (ref) => ref.where('name', '==', name).limit(1)).valueChanges()
    .pipe(
      take(1),
      map((ret) => {
        return (Array.isArray(ret) && ret.length) ? new Role(ret[0]) : new Role({name: 'Basic', uqid: environment.defaultRole});
      })
    );
  }

  getUsers(): Promise<NormalUser[]> {
    return new Promise((resolve, reject) => {
      const users: NormalUser[] = [];
      this.afs.collection('user').ref.get()
      .then((userRefs) => {
        userRefs.forEach((ref) => {
          users.push(new NormalUser(ref.data()));
        });
        resolve(users);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  sendPasswordReset(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  getOAuthCredentials(provider: string): Promise<auth.auth.UserCredential> {
    if (provider === 'google.com') {
      return this.afAuth.signInWithPopup(new auth.auth.GoogleAuthProvider());
    } else if (provider === 'facebook.com') {
      return this.afAuth.signInWithPopup(new auth.auth.FacebookAuthProvider());
    } else {
      return of(null).toPromise();
    }
  }

  deleteUser(firebaseUser: User.User): Promise<void> {
    return firebaseUser.delete();
  }

  async updateInvite(invite: Invite): Promise<void> {
    const invites: any = await this.afs.collection<Invite[]>('invites').doc(invite.recipientEmail).valueChanges().pipe(take(1)).toPromise();
    if (Array.isArray(invites)) {
      const index = invites.findIndex(i => i.uqid === invite.uqid);
      if (index !== -1) {
        invites.splice(index, 1);
      }
    }
    return this.afs.collection('invites').doc(invite.recipientEmail).update({ invites: invites });
  }

  getInvites(user: NormalUser): Observable<Invite[]> {
    return this.afs.collection<any>('invites').doc(user.email.toLowerCase()).valueChanges()
    .pipe(
      map((ret: {invites: Invite[]}) => {
        if (ret && ret.invites.length > 0) {
          return ret.invites.map(i => new Invite(i));
        } else {
          return [];
        }
      })
    );
  }

  async addInvites(invites: Invite[]): Promise<void> {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < invites.length; i++) {
        invites[i].uqid = this.afs.createId();
        const inv = await this.afs.collection<Invite[]>('invites').doc(invites[i].recipientEmail).valueChanges().pipe(
          take(1),
          map(ret => {
            if (Array.isArray(ret)) {
              return ret.map((t: any) => new Invite(t));
            } else {
              return [];
            }
          })
          ).toPromise();
        inv.push(invites[i]);
        await this.afs.collection('invites').doc(invites[i].recipientEmail).set({invites: inv.map(j => j.simplify())});
      }
      resolve();
    });
  }

  getGroups(user: NormalUser): Observable<Group[]> {
    const refs = user.groupIds.map(gid => {
      return this.afs.collection<Group>('groups').doc(gid).valueChanges().pipe(take(1));
    });
    if (refs.length) {
      return combineLatest([...refs])
        .pipe(
          map(ret => {
            if (Array.isArray(ret)) {
              return ret.map(g => {
                  return new Group(g);
              });
            } else {
                return [];
            }
          })
        );
    } else {
      return of([]);
    }
  }

  getGroupById(gid: string): Observable<Group> {
    return this.afs.collection<Group>('groups').doc(gid).valueChanges().pipe(
      take(1),
      map(ret => new Group(ret))
    );
  }

  async intitializeUser(user: User.User) {
    const account = new NormalUser({
      uid: user.uid,
      email: user.email,
      joinDate: user.metadata.creationTime,
      lastLogin: user.metadata.lastSignInTime,
      role: this.defaultRole,
      groupIds: [],
      seenNews: []
    });
    await this.afs.collection('user').doc(user.uid).set(account.simplify());
    this.templateService.initGeneralTemplates(DataLevel.private, user.uid);
    this.templateService.initializePackingData(DataLevel.private, user.uid);
  }

  async createGroup(group: Group, user: NormalUser): Promise<void> {
    group.uqid = this.afs.createId();
    user.groupIds.push(group.uqid);
    await this.afs.collection('user').doc(user.uid).update(user.simplify());
    return this.afs.collection('groups').doc(group.uqid).set(group.simplify());
  }

  leaveGroup(groupId: string, user: NormalUser): Promise<void> {
    const index = user.groupIds.indexOf(groupId);
    user.groupIds.splice(index, 1);
    return this.afs.collection('user').doc(user.uid).update(user.simplify());
  }

  updateGroup(group: Group): Promise<void> {
    return this.afs.collection('groups').doc(group.uqid).update(group.simplify());
  }

  public updateUser(user: NormalUser): Promise<void> {
    return this.afs.collection('user').doc(user.uid).update(user.simplify());
  }

  private updateUserData(user: User.User) {
    const userRef: AngularFirestoreDocument<NormalUser> = this.afs.doc(
      `user/${user.uid}`
    );
    this.afs.collection<NormalUser>('user').doc(user.uid).valueChanges().pipe(take(1)).subscribe((myUser: NormalUser) => {
      const data = new NormalUser({
        uid: user.uid,
        email: user.email,
        joinDate: user.metadata.creationTime,
        lastLogin: user.metadata.lastSignInTime,
        role: myUser.role,
        groupIds: myUser.groupIds,
        seenNews: myUser.seenNews,
        subscriptionUqid: myUser.subscriptionUqid
      });
      return userRef.set(data.simplify());
    });
  }

  logout(redirect: boolean) {
    this.afAuth.signOut().then(() => {
      this.globalService.destroySubscriptions();
      if (redirect) {
        this.router.navigate(['/login']);
      }
    });
  }
}
