import { trimIfExists } from '../utils/string-utils';

export class ShoppingListItem {
  public categoryUqid?: string;
  public checked?: boolean;
  public coupon?: boolean;
  public location?: string;
  public name?: string;
  public parentUqid?: string;
  public uqid?: string;

  constructor(item?: any) {
    this.categoryUqid = (item && item.categoryUqid) ? item.categoryUqid : '';
    this.checked = (item && item.checked) ? item.checked : false;
    this.coupon = (item && item.coupon) ? item.coupon : false;
    this.location = (item && item.location) ? item.location : '';
    this.name = (item && item.name) ? item.name : '';
    this.parentUqid = (item && item.parentUqid) ? item.parentUqid : undefined;
    this.uqid = (item && item.uqid) ? item.uqid : undefined;

  }

  public simplify(): any {
    return {
      categoryUqid: trimIfExists(this.categoryUqid),
      checked: this.checked,
      coupon: this.coupon,
      location: trimIfExists(this.location),
      name: trimIfExists(this.name),
      parentUqid: trimIfExists(this.parentUqid),
      uqid: trimIfExists(this.uqid)
    };
  }

  public updateItem(item: ShoppingListItem) {
    this.categoryUqid = item.categoryUqid;
    this.checked = item.checked;
    this.coupon = item.coupon;
    this.location = item.location;
    this.name = item.name;
  }
}
