<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Start Project</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="project" class="modal-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>Project Name</mat-label>
      <input matInput [(ngModel)]="project.name">
    </mat-form-field>
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput rows="3" [(ngModel)]="project.description"></textarea>
    </mat-form-field>
    <mat-form-field class="full-width" color="accent" appearance="outline" (click)="dd.open()">
      <mat-label>Due Date</mat-label>
      <input matInput [min]="today" [(ngModel)]="dueDate" [matDatepicker]="dd" disabled>
      <mat-datepicker-toggle matSuffix [for]="dd"></mat-datepicker-toggle>
      <mat-datepicker #dd disabled="false"></mat-datepicker>
    </mat-form-field>
    <div class="full-width" *ngIf="groups.length > 0" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
      <div class="full-width" style="color: #a6a6a6">Add to...</div>
      <mat-radio-group class="full-width" [(ngModel)]="project.accessId" fxLayout="column" fxLayoutGap="5px">
        <mat-radio-button [value]="user.uid">Private</mat-radio-button>
        <mat-radio-button [value]="group.uqid" *ngFor="let group of groups">{{group.name}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="done()">
        <div class="modal-footer-text" [ngClass]="{'disabled': !isValid()}">Done</div>
        <mat-icon class="modal-footer-icon" [ngClass]="{'disabled': !isValid()}">check</mat-icon>
      </div>
    </div>
  </div>
</div>
