<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">{{task.uqid ? 'Edit' : 'Add'}} Task</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div *ngIf="task" class="modal-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-form-field class="small-form full-width" color="accent" appearance="outline">
      <mat-label>Task Name</mat-label>
      <input matInput [(ngModel)]="task.name">
    </mat-form-field>
    <mat-form-field class="small-form full-width" color="accent" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput rows="3" [(ngModel)]="task.description"></textarea>
    </mat-form-field>
    <span *ngIf="canChangeStatus" style="color: #a6a6a6" class="full-width">Status</span>
    <mat-button-toggle-group *ngIf="canChangeStatus" class="full-width" appearance="legacy" style="flex: 0 0 auto;"
      fxLayout="row" fxLayoutAlign="start center"
      [value]="task.status"
      (change)="task.status = $event.value;">
      <mat-button-toggle fxFlex="grow" [value]="WorkflowStatus.ToDo">To Do</mat-button-toggle>
      <mat-button-toggle fxFlex="grow" [value]="WorkflowStatus.InProgress">In Progress</mat-button-toggle>
      <mat-button-toggle fxFlex="grow" [value]="WorkflowStatus.Done">Done</mat-button-toggle>
    </mat-button-toggle-group>
    <span style="color: #a6a6a6" class="full-width">Priority</span>
    <mat-button-toggle-group class="full-width" appearance="legacy" style="flex: 0 0 auto;"
      fxLayout="row" fxLayoutAlign="start center"
      [value]="task.priority"
      (change)="task.priority = $event.value;">
      <mat-button-toggle fxFlex="grow" [value]="Priority.Low">Low</mat-button-toggle>
      <mat-button-toggle fxFlex="grow" [value]="Priority.Medium">Medium</mat-button-toggle>
      <mat-button-toggle fxFlex="grow" [value]="Priority.High">High</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="task.uqid" fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="delete()">
        <mat-icon class="modal-footer-icon">delete</mat-icon>
        <div class="modal-footer-text">Delete</div>
      </div>
    </div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="done()">
        <div class="modal-footer-text" [ngClass]="{'disabled': !taskIsValid}">Done</div>
        <mat-icon class="modal-footer-icon" [ngClass]="{'disabled': !taskIsValid}">check</mat-icon>
      </div>
    </div>
  </div>
</div>
