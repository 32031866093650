import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Recipe } from '../classes/recipe';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DataLevel } from '../classes/data-level';
import { User } from '../classes/user';

const RECIPE = 'recipe';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {
  private _db: AngularFirestore;

  constructor(db: AngularFirestore,
              private storage: AngularFireStorage) {
    this._db = db;
  }

  private getStartingRef(from: DataLevel, id: string): AngularFirestoreDocument {
    if (from === DataLevel.group) {
      return this._db.collection('group-data').doc(id);
    } else {
      return this._db.collection('user-data').doc(id);
    }
  }

  public getAllRecipes(user: User): Observable<Recipe[]> {
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<Recipe>(RECIPE).valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<Recipe>(RECIPE).valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
      map(ret => {
          if (Array.isArray(ret)) {
            return ret.map(r => {
              return new Recipe(r);
            });
          } else {
            return [];
          }
        })
      );
  }

  public getRecipesByGroup(from: DataLevel, id: string): Observable<Recipe[]> {
    return this.getStartingRef(from, id).collection<Recipe>(RECIPE)
      .valueChanges()
      .pipe(
        map(ret => {
          if (Array.isArray(ret)) {
            return ret.map(r => {
              return new Recipe(r);
            });
          } else {
            return [];
          }
        })
      );
  }

  public getRecipeByUqid(from: DataLevel, id: string, uqid: string): Observable<Recipe> {
    return this.getStartingRef(from, id).collection<Recipe>(RECIPE).doc(uqid)
      .valueChanges()
      .pipe(
        map(ret => {
          return new Recipe(ret);
        })
      );
  }

  public updateRecipe(from: DataLevel, id: string, recipe: Recipe): Promise<void> {
    let isNew = false;
    if (!recipe.uqid) {
      recipe.uqid = this._db.createId();
      isNew = true;
    }
    for (let i = 0; i < recipe.items.length; i++) {
      recipe.items[i].parentUqid = recipe.uqid;
      if (!recipe.items[i].uqid) {
        recipe.items[i].uqid = this._db.createId();
      }
    }
    if (isNew) {
      return this.getStartingRef(from, id).collection(RECIPE).doc(recipe.uqid).set(recipe.simplify());
    } else {
      return this.getStartingRef(from, id).collection(RECIPE).doc(recipe.uqid).update(recipe.simplify());
    }
  }

  public addImage(id: string, recipe: Recipe, image: File): Promise<any> {
      if (!recipe.fileUqid) {
        recipe.fileUqid = this._db.createId();
      }
      return this.storage.upload(`${id}/recipes/${recipe.fileUqid}.jpg`, image).then();
  }

  // tslint:disable-next-line: max-line-length
  public copyRecipe(to: DataLevel, id: string, recipe: Recipe, categoryIdMap: Map<string, string>): Promise<void> {
    const newRecipe = new Recipe(recipe);
    newRecipe.accessId = id;
    newRecipe.uqid = this._db.createId();
    for (let i = 0; i < recipe.items.length; i++) {
      recipe.items[i].categoryUqid = categoryIdMap.get(recipe.items[i].categoryUqid);
      recipe.items[i].parentUqid = newRecipe.uqid;
    }
    return this.getStartingRef(to, id).collection(RECIPE).doc(newRecipe.uqid).set(newRecipe.simplify());
  }

  public getImage(id: string, uqid: string): Observable<any> {
    return this.storage.ref(`${id}/recipes/${uqid}.jpg`).getDownloadURL();
  }

  public deleteRecipe(from: DataLevel, id: string, uqid: string): Promise<void> {
    return this.getStartingRef(from, id).collection(RECIPE).doc(uqid).delete();
  }

  public deleteImage(id: string, uqid: string): Observable<void> {
    return this.storage.ref(`${id}/recipes/${uqid}.jpg`).delete();
  }
}
