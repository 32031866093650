export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAQ9Ln_9QQnptAi1_AmEvUBLwP3WzhV6Kk',
    authDomain: 'swistible.firebaseapp.com',
    databaseURL: 'https://swistible.firebaseio.com',
    projectId: 'swistible',
    storageBucket: 'swistible.appspot.com',
    messagingSenderId: '715917098930',
    appId: '1:715917098930:web:44b5818a51d8e7ef7c6eab',
    measurementId: 'G-TE2CSE750J'
  },
  cloudScriptureAPIKey: 'KSK2KQN-RNY4K3T-P8YK23J-M6KW6A9',
  publicAccess: true,
  adsenseKey: 'ca-pub-5046888781306900',
  defaultRole: '84e5T3en7Edc4WIA9XJK',
  analyticsMeasureId: 'G-TE2CSE750J',
  silverPlanId: 'P-6G641468SL7610820L74P6DY'
};


