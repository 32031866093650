<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Add Trip</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div class="modal-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-horizontal-stepper #stepper class="full-width">
      <mat-step [completed]="false">
          <ng-template matStepLabel>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon color="accent">date_range</mat-icon>
                <div style="color: #A6A6A6;">&</div>
                <mat-icon color="accent">location_on</mat-icon>
            </div>
          </ng-template>
        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin-bottom: 10px; margin-top: 10px; padding: 10px;">
          <mat-form-field class="full-width" color="accent" appearance="outline">
            <mat-label>Destination</mat-label>
            <input matInput [(ngModel)]="destination">
          </mat-form-field>
          <div class="full-width" *ngIf="groups.length > 0" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div class="full-width" style="color: #a6a6a6">Add to...</div>
            <mat-radio-group  class="full-width" [(ngModel)]="trip.accessId" (change)="updateSelects()" fxLayout="column" fxLayoutGap="5px">
              <mat-radio-button [value]="user.uid">Private</mat-radio-button>
              <mat-radio-button [value]="group.uqid" *ngFor="let group of groups">{{group.name}}</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field class="full-width" color="accent" appearance="outline" (click)="sd.open()">
            <mat-label>Start Date</mat-label>
            <input matInput [min]="today" [(ngModel)]="startDate" [matDatepicker]="sd" disabled>
            <mat-datepicker-toggle matSuffix [for]="sd"></mat-datepicker-toggle>
            <mat-datepicker #sd disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="full-width" color="accent" appearance="outline" (click)="ed.open()">
            <mat-label>End Date</mat-label>
            <input matInput [min]="startDate" [(ngModel)]="endDate" [matDatepicker]="ed" disabled>
            <mat-datepicker-toggle matSuffix [for]="ed"></mat-datepicker-toggle>
            <mat-datepicker #ed disabled="false"></mat-datepicker>
          </mat-form-field>     
        </div>
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel>
          <mat-icon color="accent">list</mat-icon>
        </ng-template>
        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="full-width mat-elevation-z1" (click)="$event.stopPropagation()" *ngFor="let category of selectCategories">
            <div class="full-width" style="padding: 10px;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-checkbox [(ngModel)]="category.checked" (change)="categoryChecked(category)"></mat-checkbox>
              <mat-icon color="accent">{{ category.category.icon }}</mat-icon>
              <div fxFlex="grow">{{category.category.name}}</div>
              <mat-icon class="pointer" (click)="category.expanded = !category.expanded;" *ngIf="!category.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon class="pointer" (click)="category.expanded = !category.expanded;" *ngIf="category.expanded">keyboard_arrow_up</mat-icon>
            </div>
            <div *ngIf="category.expanded" class="full-width" style="padding: 10px; margin-left: 10px" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="full-width" *ngFor="let item of category.items" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-checkbox [(ngModel)]="item.checked"></mat-checkbox>
                <div fxFlex="grow">{{displayItem(item)}}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="currentStep === 2" fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
      <div matStepperPrevious class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="goBack(stepper)">
        <mat-icon class="modal-footer-icon">keyboard_arrow_left</mat-icon>
        <div class="modal-footer-text">Back</div>
      </div>
    </div>
    <div *ngIf="currentStep === 1" fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div matStepperNext class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="goForward(stepper)">
        <div class="modal-footer-text">Next</div>
        <mat-icon class="modal-footer-icon">keyboard_arrow_right</mat-icon>
      </div>
    </div>
    <div *ngIf="currentStep === 2" fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="done()">
        <div class="modal-footer-text" [ngClass]="{'disabled': !isValid()}">Done</div>
        <mat-icon class="modal-footer-icon"  [ngClass]="{'disabled': !isValid()}">check</mat-icon>
      </div>
    </div>
  </div>
</div>
