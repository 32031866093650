<div class="full-width full-height scripture-container background-bg" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <div *ngIf="!isLoading" class="scripture-title accent-c">Verse of the day</div>
    <div *ngIf="verse && !isLoading" class="verse-of-the-day" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px">
        <div class="verse-text">"{{verse.text}}"</div>
        <div fxFlexAlign="end" class="verse-ref">- {{verse.formatted}} (NKJV)</div>
    </div>
    <div *ngIf="!isLoading" class="scripture-title accent-c">Bible Readings Day {{now.format('DDD')}}</div>

    <div *ngIf="readings && !isLoading" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div class="daily-reading" *ngFor="let reading of readings" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
            <div>{{reading.passage}}</div>
        </div>
    </div>
    <div *ngIf="!isLoading" class="clickable" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="6px" (click)="$event.stopPropagation(); goToFull()">
        <div style="color: #a6a6a6; font-size: 12px;">Read full passages at:</div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
            <mat-icon class="scripture-icon" svgIcon="cloud_scripture"></mat-icon>
            <div class="scripture-link">cloudscripture.com</div>
        </div>
    </div>
    <div *ngIf="!isLoading" class="full-width copyright">Scripture taken from the New King James Version. Copyright © 1982 by Thomas Nelson, Inc. Used by permission. All rights reserved.</div>
    <div *ngIf="isLoading" class="loading-mask">
        <mat-spinner diameter="75" color="primary"></mat-spinner>
    </div>
</div>