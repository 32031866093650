import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, Subscription, take } from 'rxjs';
import { DataLevel } from 'src/app/classes/data-level';
import { Group } from 'src/app/classes/group';
import { Project } from 'src/app/classes/project';
import { ShoppingList } from 'src/app/classes/shopping-list';
import { Task } from 'src/app/classes/task';
import { Trip } from 'src/app/classes/trip';
import { User } from 'src/app/classes/user';
import { BannerService } from 'src/app/services/banner.service';
import { GlobalService } from 'src/app/services/global.service';
import { ShoppingService } from 'src/app/services/shopping.service';
import { StatsService } from 'src/app/services/stats.service';
import { TaskService } from 'src/app/services/task.service';
import { AuthService } from '../core/auth/auth.service';
import moment from 'moment';
import { WorkflowStatus } from 'src/app/classes/workflow-status';
import { EditTaskComponent } from './edit-task/edit-task.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { updateTaskList } from 'src/app/utils/general-utils';
import { AddShoppingListComponent } from '../shared/add-shopping-list/add-shopping-list.component';
import { EditListComponent } from './edit-list/edit-list.component';
import { TravelService } from 'src/app/services/travel.service';
import { AddTripComponent } from './add-trip/add-trip.component';
import { EditTripComponent } from './edit-trip/edit-trip.component';
import { PackingCategoryTemplate } from 'src/app/classes/packing-category-template';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';

export const START_ORDER = 100000000;
export const ORDER_STEP = 10000;

@Component({
  selector: 'swan-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  lists: ShoppingList[] = [];
  trips: Trip[] = [];
  projects: Project[] = [];
  tasks: Task[] = [];

  user: User;
  groups: Group[] = [];
  groupsWithPrivate: Group[] = []
  groupLabelMap: {[id: string]: string} = {};
  ready = new Subject<void>();
  shoppingSub = new Subject<void>()
  tripSub = new Subject<void>()
  taskSub = new Subject<void>()
  projectSub = new Subject<void>()
  categorySub = new Subject<void>()
  nextOrder: number = START_ORDER;
  doneCount = 0;
  categories: PackingCategoryTemplate[] = [];
  listTimeoutId = -1;
  tripTimeoutId = -1;
  taskTimeoutId = -1;
  projectTimeoutId = -1;

  disposables: Subscription[] = [];
  subscribeTo: Subject<void>[] = []

  loading = true;

  selectedTask: Task = new Task();
  WorkflowStatus = WorkflowStatus;


  constructor(private router: Router,
              private bannerService: BannerService,
              private shoppingService: ShoppingService,
              private travelService: TravelService,
              private taskService: TaskService,
              private dialog: MatDialog,
              private popup: MatSnackBar,
              private statsService: StatsService,
              private authService: AuthService,
              private globalService: GlobalService) {

    this.subscribeTo = [
      this.shoppingSub,
      this.tripSub,
      this.categorySub,
      this.taskSub,
      this.projectSub
    ];

    this.ready.subscribe({
      next: undefined,
      error: undefined,
      complete: () => {
        this.loading = false;
      }
    });

    const checkIsReady = () => {
      this.doneCount++;

      if (this.doneCount === this.subscribeTo.length) {
        this.ready.complete();
      }
    }

    for (const item of this.subscribeTo) {
      this.disposables.push(
        item.subscribe({
          next: undefined,
          error: undefined,
          complete: checkIsReady
        })
      );
    }
 
    this.authService.user.pipe(take(1)).subscribe((user) => {
      this.user = user;
      this.user.roleObj = this.authService.myRole.value;
      this.groupLabelMap[user.uid] = '';
      this.authService.getGroups(this.user).subscribe((groups) => {
        this.groups = groups;
        this.groupsWithPrivate = [new Group({name: '', uqid: user.uid})].concat(groups);
        groups.forEach((group) => {
          this.groupLabelMap[group.uqid] = group.name;
        });
      });

      this.globalService.getShoppingLists(this.user).then(() => {
        this.globalService.shoppingLists.subscribe((lists) => {
          this.lists = lists;
          this.shoppingSub.complete();
        });
      })

      this.globalService.getTrips(this.user).then(() => {
        this.globalService.trips.subscribe((trips) => {
          this.trips = trips;
          this.tripSub.complete();
        });
      })

      this.globalService.getPackingTemplates(this.user).then(() => {
        this.globalService.packingTemplates.subscribe((categories) => {
          this.categories = categories;
          this.categorySub.complete()
        });
      })

      this.globalService.getProjects(this.user).then(() => {
        this.globalService.projects.subscribe((projects) => {
          this.projects = projects;
          this.projectSub.complete();
        });
      })

      this.globalService.getTasks(this.user).then(() => {
        this.globalService.tasks.subscribe((tasks) => {
          updateTaskList(this.tasks, tasks);
          if (this.tasks.length) {
            this.nextOrder = Math.min(...this.tasks.map(t => t.order)) - ORDER_STEP;
          } else {
            this.nextOrder = START_ORDER;
          }
          this.taskSub.complete();
        });
      })
    });
    this.bannerService.setFooterOptions([]);
    this.bannerService.setBackUrl('');
  }

  ngOnDestroy(): void {
    if (this.listTimeoutId != -1) {
      window.clearTimeout(this.listTimeoutId);
    }
    if (this.tripTimeoutId != -1) {
      window.clearTimeout(this.tripTimeoutId);
    }
    if (this.taskTimeoutId != -1) {
      window.clearTimeout(this.taskTimeoutId);
    }
    this.listTimeoutId = -1;
    this.taskTimeoutId = -1;
    this.tripTimeoutId = -1;
    this.dispose();
  }

  dispose() {
    this.disposables.forEach((disposable) => {
      if (disposable) {
        disposable.unsubscribe();
      }
    });
    this.disposables = [];
  }

  addProject() {
    const data = {
      user: this.user,
      groups: this.groups
    };
    this.dialog.open(AddProjectComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false})
    .afterClosed().subscribe((ret: Project) => {
      if (ret) {
        this.updateProject(ret);
      }
    });
  }

  editProject(project: Project) {
    const data = {
      project: project,
      user: this.user,
      groups: this.groups
    };
    this.dialog.open(EditProjectComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false}).afterClosed().subscribe();
  }

  openProject(project: Project) {
    if (this.projectTimeoutId != -1) {
      window.clearTimeout(this.projectTimeoutId);
      this.projectTimeoutId = -1;
      this.editProject(project)
    } else {
      this.projectTimeoutId = window.setTimeout(() => {
        this.projectTimeoutId = -1;
        this.router.navigate(['app/todo/project', this.getRouteAccessString(project.accessId), project.uqid]);
      }, 300)
    }
  }

  addList() {
    const data = {
      user: this.user,
      groups: this.groups
    };
    this.dialog.open(AddShoppingListComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false})
    .afterClosed().subscribe((list: ShoppingList) => {
      if (list) {
        this.statsService.incrementStat('createList');
        this.updateList(list);
      }
    });
  }

  openList(list: ShoppingList) {
    if (this.listTimeoutId != -1) {
      window.clearTimeout(this.listTimeoutId);
      this.listTimeoutId = -1;
      this.editList(list)
    } else {
      this.listTimeoutId = window.setTimeout(() => {
        this.listTimeoutId = -1;
        this.router.navigate(['app/shopping', this.getRouteAccessString(list.accessId), list.uqid]);
      }, 300)
    }
  }

  updateList(list: ShoppingList) {
    this.shoppingService.updateList(DataLevel.fromExprssion(list.accessId === this.user.uid), list.accessId, list)
    .then()

  }

  getRouteAccessString(accessId: string): string {
    const ret = (this.groupLabelMap[accessId] === '') ? 'private' : 'group';
    return `${ret}_${accessId}`;
  }

  deleteList(list: ShoppingList) {
    this.shoppingService.deleteList(DataLevel.fromExprssion(list.accessId === this.user.uid), list.accessId, list)
    .then()
  }

  editList(list: ShoppingList) {
    const data = {
      user: this.user,
      list: list,
      groups: this.groups
    };
    this.dialog.open(EditListComponent, {width: '85vw', maxHeight: '85vh', data: data, autoFocus: false}).afterClosed().subscribe();
  }

  baseProgressWidth(totalItems: number, numberChecked: number): string {
    const total = totalItems;
    if (total === 0) {
      return '';
    }
    const numChecked = numberChecked;
    return `${((total - numChecked) / total) * 100}%`
  }

  progressWidth(totalItems: number, numberChecked: number): string {
    const total = totalItems;
    if (total === 0) {
      return '';
    }
    const numChecked = numberChecked;
    return `${(numChecked / total) * 100}%`
  }

  addComment(task: Task) {
    const data = {
      task: task,
      user: this.user
    };
    this.dialog.open(AddCommentComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false});
  }

  addTrip() {
    const data = {
      categories: this.categories,
      user: this.user,
      groups: this.groups
    };
    this.dialog.open(AddTripComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false})
    .afterClosed().subscribe((ret: Trip) => {
      if (ret) {
        this.statsService.incrementStat('createTrip');
        this.updateTrip(ret);
      }
    });
  }

  deleteTrip(trip: Trip) {
    this.travelService.deleteTrip(DataLevel.fromExprssion(trip.accessId === this.user.uid), trip.accessId, trip).then()
  }

  updateTrip(trip: Trip) {
    this.travelService.updateTrip(DataLevel.fromExprssion(trip.accessId === this.user.uid), trip.accessId, trip).then()
  }

  openTrip(trip: Trip) {
    if (this.tripTimeoutId != -1) {
      window.clearTimeout(this.tripTimeoutId);
      this.tripTimeoutId = -1;
      this.editTrip(trip)
    } else {
      this.tripTimeoutId = window.setTimeout(() => {
        this.tripTimeoutId = -1;
        this.router.navigate(['app/travel', this.getRouteAccessString(trip.accessId), trip.uqid]);
      }, 300)
    }
  }

  editTrip(trip: Trip) {
    const data = {
      trip: trip,
      user: this.user,
      groups: this.groups
    };
    this.dialog.open(EditTripComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false}).afterClosed().subscribe();
  }

  ngOnInit() {
  }

  updateTask(task: Task) {
    this.taskService.updateTask(DataLevel.fromExprssion(task.accessId === this.user.uid), task.accessId, task).then()
  }

  updateProject(project: Project) {
    this.taskService.updateProject(DataLevel.fromExprssion(project.accessId === this.user.uid), project.accessId, project)
    .then()
  }

  addTask() {
    let task = new Task();
    task.accessId = this.user.uid;
    const data = {
      task: task,
      user: this.user,
      canChangeStatus: true
    };
    this.dialog.open(EditTaskComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false})
      .afterClosed().subscribe((t) => {
        if (t) {
          this.bannerService.loading.next(true);
          task = t
          task.order = this.nextOrder;
          this.nextOrder -= ORDER_STEP;
          this.taskService.updateTask(DataLevel.fromExprssion(task.accessId === this.user.uid), task.accessId, task)
            .then(() => {
              this.bannerService.loading.next(false);
            })
        }
      });
  }


  editTask(task: Task) {
    let taskCopy = new Task(task);
    if (this.taskTimeoutId != -1) {
      this.taskTimeoutId = -1;
      const data = {
        task: taskCopy,
        user: this.user,
        canChangeStatus: true
      };
      this.dialog.open(EditTaskComponent, {data: data, width: '85vw', maxHeight: '85vh', autoFocus: false})
        .afterClosed().subscribe((t) => {
          if (t) {
            task = t
            this.taskService.updateTask(DataLevel.fromExprssion(task.accessId === this.user.uid), task.accessId, task)
              .then()
          }
        });
    } else {
      this.taskTimeoutId = window.setTimeout(() => {
        this.taskTimeoutId = -1
      }, 300)
    }
    
  }

  projectIsDone(project: Project): boolean {
    return project.tasks.length && project.tasks.map(t => t.status).filter(s => s !== WorkflowStatus.Done).length === 0;
  }

  displayDueDate(project: Project): string {
    if (this.projectIsDone(project)) {
      return '';
    }
    const diff = project.dueDate.diff(moment(), 'days');
    if (diff < 0) {
      return 'Overdue';
    } else if (diff === 0) {
      return 'Due Today';
    } else {
      return `${diff} Day${(diff === 1) ? '' : 's'}`;
    }
  }

  updateSelectedTaskStatus(task: Task, status: WorkflowStatus) {
    if (task.status != status) {
      task.status = status;
      this.updateTask(task);
    }
  }

  taskDrop(event: CdkDragDrop<Task[]>) {
    if (event.currentIndex === 0) {
      this.tasks[event.previousIndex].order = this.tasks[0].order - ORDER_STEP;
      this.nextOrder = this.tasks[event.previousIndex].order - ORDER_STEP;
    } else if (event.currentIndex === this.tasks.length - 1) {
      this.tasks[event.previousIndex].order = this.tasks[event.currentIndex].order + ORDER_STEP;
    } else {
      const above = this.tasks[event.currentIndex - 1].order;
      const below = this.tasks[event.currentIndex + 1].order;
      this.tasks[event.previousIndex].order = (above + below) / 2;
    }
    this.updateTask(this.tasks[event.previousIndex]);
    moveItemInArray(this.tasks, event.previousIndex, event.currentIndex);
  }

}
