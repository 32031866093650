import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackingCategoryTemplate } from '../classes/packing-category-template';
import { StoreCategory } from '../classes/store-category';
import { DataLevel } from '../classes/data-level';
import { User } from '../classes/user';

const PACKING = 'packing-category-templates';
const GENERAL = 'general-templates';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private _db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this._db = db;
  }

  private getStartingRef(from: DataLevel, id: string): AngularFirestoreDocument {
    if (from === DataLevel.group) {
      return this._db.collection('group-data').doc(id);
    } else {
      return this._db.collection('user-data').doc(id);
    }
  }

  public getAllPackingTemplates(user: User): Observable<PackingCategoryTemplate[]> {
    // tslint:disable-next-line: max-line-length
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<PackingCategoryTemplate>(PACKING).valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<PackingCategoryTemplate>(PACKING).valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
      map(ret => {
          if (Array.isArray(ret)) {
              return ret.map(p => {
                  return new PackingCategoryTemplate(p);
              });
          } else {
              return [];
          }
        })
      );
  }

  public getAllStoreCategories(user: User): Observable<StoreCategoryContainer[]> {
    // tslint:disable-next-line: max-line-length
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<any>(GENERAL).doc('store-categories').valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<any>(GENERAL).doc('store-categories').valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map((ret: any) => {
        const containers: StoreCategoryContainer[] = [];
        ret.forEach((val) => {
          containers.push({
            accessId: val.accessId,
            categories: val.categories.map((c) => {c.accessId = val.accessId; return new StoreCategory(c); })
          });
        });
        return containers;
      })
    );
  }

  public getPackingTemplatesByGroup(from: DataLevel, id: string): Observable<PackingCategoryTemplate[]> {
    return this.getStartingRef(from, id).collection<PackingCategoryTemplate>(PACKING).valueChanges()
      .pipe(
        map(ret => {
          if (Array.isArray(ret)) {
              return ret.map(p => {
                  return new PackingCategoryTemplate(p);
              });
          } else {
              return [];
          }
        })
      );
  }

  public getStoreCategoriesByGroup(from: DataLevel, id: string): Observable<StoreCategoryContainer> {
    return this.getStartingRef(from, id).collection<any>(GENERAL).doc('store-categories')
      .valueChanges()
      .pipe(
        map((ret: StoreCategoryContainer) => {
          return {
            accessId: ret.accessId,
            categories: ret.categories.map((c) => {c.accessId = ret.accessId; return new StoreCategory(c); })
          };
        })
      );
  }

  public getPackingTemplateByUqid(from: DataLevel, id: string, uqid: string): Observable<PackingCategoryTemplate> {
    return this.getStartingRef(from, id).collection<PackingCategoryTemplate>(PACKING).doc(uqid)
      .valueChanges()
      .pipe(
        map(ret => {
          return new PackingCategoryTemplate(<PackingCategoryTemplate>ret);
        })
      );
  }

  public initGeneralTemplates(from: DataLevel, id: string) {
    const categories = {
      accessId: id,
      categories: [
        {uqid: this._db.createId(), name: 'Aisles', icon: 'store'},
        {uqid: this._db.createId(), name: 'Cold Stuff', icon: 'kitchen'},
        {uqid: this._db.createId(), name: 'Deli', icon: 'cake'},
        {uqid: this._db.createId(), name: 'Electronics', icon: 'important_devices'},
        {uqid: this._db.createId(), name: 'Frozen', icon: 'ac_unit'},
        {uqid: this._db.createId(), name: 'Meat', icon: 'restaurant'},
        {uqid: this._db.createId(), name: 'Other', icon: 'category'},
        {uqid: this._db.createId(), name: 'Produce', icon: 'nature'},
        {uqid: this._db.createId(), name: 'Pharmacy', icon: 'healing'},
        {uqid: this._db.createId(), name: 'Seafood', icon: 'waves'},
        {uqid: this._db.createId(), name: 'Sporting Goods', icon: 'rowing'},
      ]
    };
    this.getStartingRef(from, id).collection(GENERAL).doc('store-categories').set(categories);
  }

  initializePackingData(from: DataLevel, id: string) {
    const items: any[] = [];
    items.push(
      {
        accessId: id,
        icon: 'local_laundry_service',
        items: [
          '1&&0&&Outfits',
          '1&&0&&Socks',
          '1&&0&&Underware',
          'Bras',
          'Jackets',
          'Rain jackets',
          'Pjs',
          'Sports bras',
          'Shoes',
          'Belt',
          'Hats',
          'Jewelry',
          'Sunglasses',
          'Swimsuits'
        ],
        name: 'Clothing',
        uqid: this._db.createId()
      }, {
        accessId: id,
        icon: 'wc',
        items: [
          'Toothbrushes',
          'Toothpaste',
          'Face wash',
          'Moisturizer',
          'Medicine',
          'Deoderants',
          'Hairbrush',
          'Chapstick',
          'Makeup',
          'Shampoo',
          'Conditioner',
          'Body wash',
          'Razor',
          'First aid',
          'Nail clippers',
          'Lotion',
          'Hand soap',
          'Hairbows',
          'Sunscreen',
          'Nail file',
          'Aloe vera'
        ],
        name: 'Toiletries',
        uqid: this._db.createId()
      }
    );
    items.forEach((item) => {
      this.getStartingRef(from, id).collection(PACKING).doc(item.uqid).set(item);
    });
  }

  public deletePackingTemplate(from: DataLevel, id: string, templateUqid: string): Promise<void> {
    return this.getStartingRef(from, id).collection(PACKING).doc(templateUqid).delete();
  }

  updatePackingTemplate(from: DataLevel, id: string, template: PackingCategoryTemplate): Promise<void> {
    let isNew = false;
    if (!template.uqid) {
      template.uqid = this._db.createId();
      isNew = true;
    }
    if (isNew) {
      return this.getStartingRef(from, id).collection(PACKING).doc(template.uqid).set(template.simplify());
    } else {
      return this.getStartingRef(from, id).collection(PACKING).doc(template.uqid).update(template.simplify());
    }
  }

  updateStoreCategories(from: DataLevel, id: string, categories: StoreCategory[]): Promise<void> {
    categories.forEach((category, i) => {
      if (!category.uqid) { categories[i].uqid = this._db.createId(); }
    });
    return this.getStartingRef(from, id).collection(GENERAL).doc('store-categories')
      .update({accessId: id, categories: categories.map(c => c.simplify())});
  }

  public copyTemplate(to: DataLevel, id: string, template: PackingCategoryTemplate): Promise<void> {
    const newTemplate = new PackingCategoryTemplate(template);
    newTemplate.accessId = id;
    newTemplate.uqid = this._db.createId();
    return this.getStartingRef(to, id).collection(PACKING).doc(newTemplate.uqid).set(newTemplate.simplify());
  }

  copyMultipleTemplates(to: DataLevel, id: string, templates: PackingCategoryTemplate[]): Promise<void> {
    const batch = this._db.firestore.batch();
    for (let i = 0; i < templates.length; i++) {
      templates[i].accessId = id;
      templates[i].uqid = this._db.createId();
      const ref = this.getStartingRef(to, id).collection(PACKING).doc(templates[i].uqid).ref;
      batch.set(ref, templates[i].simplify());
    }
    return batch.commit();
  }
}

export interface StoreCategoryContainer {
  accessId?: string;
  categories?: StoreCategory[];
}
