import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User as NormalUser } from 'src/app/classes/user';
import User from 'firebase/compat';
import auth from 'firebase/compat/app';
import { Router } from '@angular/router';
@Component({
  selector: 'swan-delete-user', 
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  firebaseUser: User.User;
  user: NormalUser;
  password: string;
  provider: string;
  credentials: auth.auth.UserCredential;

  constructor(private ref: MatDialogRef<DeleteUserComponent>,
              @Inject(MAT_DIALOG_DATA) data: {firebaseUser: User.User, user: NormalUser},
              private authService: AuthService,
              private router: Router) {
    this.user = data.user;
    this.firebaseUser = data.firebaseUser;
    if (this.firebaseUser.providerData.length) {
      this.provider = this.firebaseUser.providerData[0].providerId;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.ref.close();
  }

  isValid(): boolean {
    const c1 = (this.provider && (this.provider === 'google.com' || this.provider === 'facebook.com') && !!this.credentials);
    const c2 = (!this.provider || (this.provider !== 'google.com' && this.provider !== 'facebook.com') && !!this.password);
    return c1 || c2;
  }

  reauthticate() {
    this.authService.getOAuthCredentials(this.provider).then((credential: auth.auth.UserCredential) => {
      this.credentials = credential;
    })
  }

  delete() {
    let authCredentials: auth.auth.AuthCredential;
    if (!this.provider || (this.provider !== 'google.com' && this.provider !== 'facebook.com')) {
      authCredentials = auth.auth.EmailAuthProvider.credential(this.firebaseUser.email, this.password);
      this.firebaseUser.reauthenticateWithCredential(authCredentials).then(() => {
        this.continueDelete();
      })
    } else {
      this.continueDelete();
    }

  }

  continueDelete() {
    this.authService.deleteUser( this.firebaseUser).then(() => {
      this.authService.logout(true);
    })
    this.close();
  }

}
