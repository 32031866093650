import { Injectable, } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  public backUrl: BehaviorSubject<string>;
  public footerOptions: BehaviorSubject<FooterOption[]>;
  public loading: BehaviorSubject<boolean>;

  constructor() {
    this.backUrl = new BehaviorSubject('');
    this.footerOptions = new BehaviorSubject([]);
    this.loading = new BehaviorSubject(false);
  }

  setFooterOptions(options: FooterOption[]) {
    options.forEach((option, i) => {
      options[i] = option;
    });
    this.footerOptions.next(options);
  }

  setBackUrl(url: string) {
    this.backUrl.next(url);
  }
}

interface FooterOption {
  icon: string;
  text: string;
  level?: 'silver' | 'gold';
}
