import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import User from 'firebase/compat';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'swan-not-verified-prompt',
  templateUrl: './not-verified-prompt.component.html',
  styleUrls: ['./not-verified-prompt.component.scss']
})
export class NotVerifiedPromptComponent implements OnInit {

  user: User.User;

  constructor(private ref: MatDialogRef<NotVerifiedPromptComponent>,
              @Inject(MAT_DIALOG_DATA) data: User.User,
              private popup: MatSnackBar) {
    this.user = data;
  }

  ngOnInit(): void {
  }

  close() {
    this.ref.close();
  }

  resend() {
    this.user.sendEmailVerification().then(() => {
      this.popup.open(`Verification email sent to ${this.user.email}`, 'Dismiss', {duration: 5000, panelClass: ['snackbar-success']});
      this.ref.close();
    });
  }

}
