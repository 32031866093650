import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { StatsService } from 'src/app/services/stats.service';

@Directive({
  selector: '[swanStats]'
})
export class StatsDirective {
  @Input() stat: string = 'unknown'

  @HostListener('click', ['$event']) onClick($event){
    this.statsService.incrementStat(this.stat);
  }

  constructor(private elementRef: ElementRef,
    private statsService: StatsService) {
    }


}
