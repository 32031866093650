import { Component, OnInit, HostListener, ViewChild, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { BannerService } from './services/banner.service';
import { Router } from '@angular/router';
import { AuthService } from './modules/core/auth/auth.service';
import { slideInOut } from './animation';
import { UpdateService } from './services/update.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InstallBannerComponent } from './components/install-banner/install-banner.component';
import { DomSanitizer } from '@angular/platform-browser';
import { OfflineMessageComponent } from './components/offline-message/offline-message.component';
import { User } from './classes/user';
import { Invite } from './classes/invite';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { AdminService } from './services/admin.service';
import { TitleService } from './services/title.service';
import { Role } from './classes/role';
import { MatSidenav } from '@angular/material/sidenav';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { Email } from './classes/email';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { GlobalService } from './services/global.service';


@Component({
  selector: 'swan-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInOut]
})
export class AppComponent implements OnInit, AfterViewInit {

  backUrl: string;
  footerOptions: FooterOption[] = [];
  activeComponent: any;
  loading = false;
  user: User;
  invites: Invite[] = [];
  showSelector = false;
  sideNavOpen = false;
  loadingUserInfo = true;

  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;
  @ViewChild('container', {static: true}) container: ElementRef;

  constructor(private bannerService: BannerService,
              private authService: AuthService,
              private router: Router,
              private updateService: UpdateService,
              private adminService: AdminService,
              private dialog: MatDialog,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private popup: MatSnackBar,
              private titleService: TitleService,
              private globalService: GlobalService,
              @Inject(DOCUMENT) private document: Document) {
    this.titleService.boot();
    this.matIconRegistry.addSvgIcon(
      'ios_share',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ios-share.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add_to_home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-to-home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'swan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/swan.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'angular',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/angular.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'firebase',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/firebase.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pwa',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pwa.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'swanscript',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Dev-Stamp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cloud_scripture',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cloud_scripture.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'support',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/support.svg')
    );
    this.updateService.checkForUpdates();
    this.bannerService.loading.subscribe((value) => {
      this.loading = value;
    });

    this.authService.user.subscribe((user) => {
      this.user = user;
      if (user && user.uid !== undefined) {
        localStorage.setItem('seenOnboarding', 'yes');
        this.authService.getInvites(this.user).subscribe((invites) => {
          this.invites = invites;
        });
        this.authService.getRoleFromId(this.user.role).subscribe((role) => {
          this.user.roleObj = role;
          this.authService.myRole.next(role);
          this.loadingUserInfo = false;
        });
      } else {
        this.loadingUserInfo = false;
        this.authService.myRole.next(new Role({name: 'Basic', uqid: environment.defaultRole}));
        const seenOnboarding: string = localStorage.getItem('seenOnboarding');
        if (!seenOnboarding || seenOnboarding !== 'yes') {
          this.router.navigate(['/onboarding'])
        }
      }
    });
    this.showInstallBanner();
  }


  @HostListener('window:offline')
  onOffline() {
    this.dialog.open(OfflineMessageComponent);
  }

  ngAfterViewInit() {
  }
  
  get getContainerRight(): number {
    if (!this.sidenav) {
      return 140;
    } else {
      return this.sidenav._getWidth() + 16;
    }
  }

  support() {
    this.document.location.href = 'https://ko-fi.com/swanscript';
  }

  navigateBack() {
    this.bannerService.loading.next(false);
    switch (this.backUrl) {
      case 'Recipes':
        this.router.navigate(['app/recipes']);
        break;
      case 'Templates':
        this.router.navigate(['app/templates']);
        break;
      case 'Home':
        this.router.navigate(['app']);
        break;
      case 'Login':
        this.router.navigate(['login']);
        break;
    }
  }

  ngOnInit() {
    const firstPageView = localStorage.getItem('firstPageView');
    if (!firstPageView) {
      localStorage.setItem('firstPageView', 'yes');
    }
    this.bannerService.backUrl.subscribe((val) => {
      this.backUrl = val;
    });
    this.bannerService.footerOptions.subscribe((options) => {
      this.footerOptions = options;
    });

  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  logout() {
    this.authService.logout(true);
  }

  get isMobile(): boolean {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  onActivate(componentRef: any) {
    this.activeComponent = componentRef;
  }

  footerClicked(option: FooterOption) {
    if (option.icon === 'playlist_add') {
      option.icon = 'add';
    }
    this.activeComponent.footerAction(option.icon);
  }

  contactUs() {
    this.dialog.open(ContactUsComponent, {width: '85vw', maxHeight: '85vh', data: this.user, autoFocus: false})
    .afterClosed().subscribe((email: Email) => {
      if (email) {
        this.adminService.submitEmail(email).then(() => {
          this.popup.open('Thank you! We will be in contact soon.', 'Dismiss', {duration: 4000, panelClass: ['snackbar-success']});
        })
      }
    });
  }

  recipeClick() {
    this.navigate('app/recipes');
  }


  openFeedback() {
    const data = {
      user: this.user
    };
    this.dialog.open(FeedbackFormComponent, {data: data, width: '85vw', maxHeight: '85vh'}).afterClosed().subscribe((feedback) => {
      if (feedback) {
        this.adminService.submitFeedback(feedback).then(() => {
          this.popup.open('Thank you for your feedback!', 'Dismiss', {duration: 3000, panelClass: ['snackbar-success']});
        })
      }
    });
  }

  showInstallBanner() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    };

    const isInStandaloneMode = () => {
      return ('standalone' in window.navigator) && ((window.navigator as any).standalone);
    };

    if (isIos() && !isInStandaloneMode()) {
      const hasSeenPrompt = localStorage.getItem('hasSeenPrompt');
      if (!hasSeenPrompt) {
        this.dialog.open(InstallBannerComponent, {position: {bottom: '10px'}});
        localStorage.setItem('hasSeenPrompt', 'yes');
      }
    }
  }

}

interface FooterOption {
  icon: string;
  text: string;
  level?: 'silver' | 'gold';
}
