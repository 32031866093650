import { trimIfExists } from '../utils/string-utils';

export class Email {
    public from?: string;
    public message?: string;
    public subject?: string;
    public uqid?: string;

    constructor(email?: any) {
        this.from = (email && email.from) ? email.from : '';
        this.message = (email && email.message) ? email.message : '';
        this.subject = (email && email.subject) ? email.subject : '';
        this.uqid = (email && email.uqid) ? email.uqid : undefined;
    }

    public simplify() {
        return {
            from: trimIfExists(<string>this.from),
            message: trimIfExists(<string>this.message),
            subject: trimIfExists(<string>this.subject),
            uqid: trimIfExists(<string>this.uqid)
        };
    }
}
