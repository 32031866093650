import { Task } from '../classes/task';

export function updateTaskList(origTasks: Task[], newTasks: Task[]) {
  if (origTasks.length) {
    newTasks.forEach((task) => {
      const tsk = origTasks.find(t => t.uqid === task.uqid);
      if (tsk) {
        if (tsk.order !== task.order) {
          origTasks.splice(origTasks.findIndex(t => t.uqid === tsk.uqid), 1);
          origTasks.splice(findTaskIndex(origTasks, task), 0, task);
        } else {
          tsk.updateTask(task);
        }
      } else {
        origTasks.splice(findTaskIndex(origTasks, task), 0, task);
      }
    });
    for (let i = origTasks.length - 1; i >= 0; i--) {
      if (newTasks.findIndex(t => t.uqid === origTasks[i].uqid) === -1) {
        origTasks.splice(i, 1);
      }
    }
  } else {
    origTasks.push(...newTasks.sort((a, b) => a.order - b.order));
  }
}

function findTaskIndex(allTasks: Task[], task: Task): number {
  let ret = 0;
  for (let i = 0; i < allTasks.length; i++) {
    ret = i;
    if (task.order < allTasks[i].order) {
      break;
    }
  }
  return ret;
}
