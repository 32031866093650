import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ShoppingList } from 'src/app/classes/shopping-list';
import moment from 'moment';
import { User } from 'src/app/classes/user';
import { Group } from 'src/app/classes/group';

@Component({
  selector: 'swan-add-shopping-list',
  templateUrl: './add-shopping-list.component.html',
  styleUrls: ['./add-shopping-list.component.scss']
})
export class AddShoppingListComponent implements OnInit {

  list: ShoppingList;
  locationInputs: LocationInput[] = [];
  today: Date;
  shoppingDate: Date;
  user: User;
  groups: Group[] = [];
  accessId: string;

  constructor(private ref: MatDialogRef<AddShoppingListComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    if (data) {
      this.user = data.user;
      this.groups = data.groups;
    }
    ref.disableClose = true;
    this.list = new ShoppingList();
    this.list.locations = [];
    this.list.name = (data.name) ? data.name : 'Groceries';
    this.list.accessId = (data.accessId) ? data.accessId : this.user.uid;
    this.today = new Date();
    this.shoppingDate = new Date();
    for (let i = 0; i < 20; i++) {
      this.locationInputs.push({location: '', disabled: false});
    }
    console.log(this.list);
  }

  ngOnInit() {
  }

  isValid(): boolean {
    return this.list && this.list.name && this.locationInputs.findIndex(il => il.location.length > 0) !== -1;
  }

  removeLocation(location: string) {
    const index = this.locationInputs.findIndex(i => i.location === location);
    if (index !== -1) {
      this.locationInputs.splice(index, 1);
    }
  }

  disableInput(input: LocationInput) {
    if (input && input.location !== '') {
      input.disabled = true;
    }
  }

  close() {
    this.ref.close();
  }

  done() {
    if (this.isValid()) {
      this.list.createDate = moment(this.today);
      this.list.shoppingDate = moment(this.shoppingDate);
      this.locationInputs.forEach((input) => {
        if (input.location) {
          this.list.locations.push(input.location);
        }
      });
      this.ref.close(this.list);
    }
  }

}

interface LocationInput {
  location: string;
  disabled: boolean;
}
