<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow" class="modal-title">Delete My Account</div>
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
            <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
            <div class="modal-close-text">Close</div>
                <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
            </div>
        </div>
    </div>
    <div class="modal-content full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div class="full-width">
            Are you sure you want to delete your account?
        </div>
        <div class="full-width">
            You can recreate your account at a later date, but all your stored data will be gone.
        </div>
        <mat-form-field *ngIf="!provider || (provider !== 'google.com' && provider !== 'facebook.com')" class="full-width" color="accent" appearance="outline">
            <mat-label>Please enter your password to continue</mat-label>
            <input matInput type="password" [(ngModel)]="password">
        </mat-form-field>
        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <button *ngIf="provider === 'google.com'" mat-raised-button class="social-login-button" (click)="reauthticate()">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <img class="social-login-img" src="assets/login/google_login.png">
                    <div class="social-login-text">Authenticate Account to Continue</div>
                </div>

            </button>
            <button *ngIf="provider === 'facebook.com'" mat-raised-button class="social-login-button" (click)="reauthticate()">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <img class="social-login-img" src="assets/login/facebook_login.png">
                    <div class="social-login-text">Authenticate Account to Continue</div>
                </div>
            </button>
            <mat-icon color="primary" *ngIf="credentials">check</mat-icon>
        </div>
    </div>
    <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
            <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="delete()">
                <div class="modal-footer-text" [ngClass]="{'disabled': !isValid()}">Delete Account</div>
                <mat-icon class="modal-footer-icon" [ngClass]="{'disabled': !isValid()}">delete_forever</mat-icon>
            </div>
        </div>
    </div>
</div>