<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow" class="modal-title">Email not verified!</div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
            <div class="modal-close-text">Close</div>
                <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
            </div>
        </div>
    </div>
    <div class="modal-content full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div class="full-width">
            Please check your email or resend a verification email as soon as possible.
        </div>
        <div class="full-width">
            If you do not see the email, try checking your spam folder.
        </div>
    </div>
    <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
            <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="resend()">
                <div class="modal-footer-text">Resend Email</div>
                <mat-icon class="modal-footer-icon">send</mat-icon>
            </div>
        </div>
    </div>
</div>