import { Component, OnInit, Inject } from '@angular/core';
import { TravelService } from 'src/app/services/travel.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Trip } from 'src/app/classes/trip';
import moment from 'moment';
import { User } from 'src/app/classes/user';
import { DataLevel } from 'src/app/classes/data-level';
import { Group } from 'src/app/classes/group';
import { GlobalService } from 'src/app/services/global.service';
import { PackingCategoryTemplate } from 'src/app/classes/packing-category-template';

@Component({
  selector: 'swan-edit-trip',
  templateUrl: './edit-trip.component.html',
  styleUrls: ['./edit-trip.component.scss']
})
export class EditTripComponent implements OnInit {

  trip: Trip;
  startDate: Date;
  endDate: Date;
  user: User;
  today: Date = new Date();
  groups: Group[] = [];
  packingTemplates: PackingCategoryTemplate[] = [];

  constructor(private ref: MatDialogRef<EditTripComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private travelService: TravelService,
              private globalService: GlobalService) {
    this.ref.disableClose = true;
    if (data) {
      this.startDate = new Date(data.trip.startDate.format());
      this.endDate = new Date(data.trip.endDate.format());
      this.trip = data.trip;
      this.user = data.user;
      this.groups = data.groups;
    }

    this.globalService.getPackingTemplates(this.user).then(() => {
      this.globalService.packingTemplates.subscribe((templates) => {
        this.packingTemplates = templates;
      });
    });
  }

  ngOnInit() {
  }

  close() {
    this.ref.close();
  }

  delete() {
    this.travelService.deleteTrip(DataLevel.fromExprssion(this.trip.accessId === this.user.uid), this.trip.accessId, this.trip).then(() => {
    })
    this.close();
  }

  updateTrip() {
    this.travelService.updateTrip(DataLevel.fromExprssion(this.trip.accessId === this.user.uid), this.trip.accessId, this.trip)
    .then()
  }

  updateStartDate() {
    this.trip.startDate = moment(this.startDate);
    this.updateTrip();
  }

  updateDestination() {
    this.trip.name = this.trip.destination;
    this.updateTrip();
  }

  updateEndDate() {
    this.trip.endDate = moment(this.endDate);
    this.updateTrip();
  }

  getGroupsWithoutCurrent(): Group[] {
    const index = this.groups.findIndex(g => g.uqid === this.trip.accessId);
    if (index === -1) {
      return this.groups;
    }
    return this.groups.filter(g => g.uqid !== this.trip.accessId).concat(new Group({name: 'Private', uqid: this.user.uid}));
  }

}
