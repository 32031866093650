import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UpdatePromptComponent } from '../components/update-prompt/update-prompt.component';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private intervalSub: Subscription;

  constructor(public updates: SwUpdate,
              public dialog: MatDialog) {
    if (updates.isEnabled) {
      this.intervalSub = interval(15000).subscribe(() => updates.checkForUpdate().then());
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
    if (this.updates.isEnabled) {
      if (this.intervalSub) {
        this.intervalSub.unsubscribe();
      }
      this.intervalSub = interval(15000).subscribe(() => this.updates.checkForUpdate().then());
    }
  }

  private promptUser(): void {
    this.dialog.open(UpdatePromptComponent, {autoFocus: false}).afterClosed().subscribe((reload) => {
      if (reload) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }
}
