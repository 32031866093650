export class WorkflowStatus {
    static readonly ToDo: WorkflowStatus = new WorkflowStatus('To Do');
    static readonly InProgress: WorkflowStatus = new WorkflowStatus('In Progress');
    static readonly Done: WorkflowStatus = new WorkflowStatus('Done');

    public readonly name: string;

    private constructor(name: string) {
      this.name = name;
    }

    static forName(name: string | WorkflowStatus) {
      const val = (name instanceof WorkflowStatus) ? name.toString() : name;
      if (val === 'To Do') { return WorkflowStatus.ToDo; }
      if (val === 'In Progress') { return WorkflowStatus.InProgress; }
      if (val === 'Done') { return WorkflowStatus.Done; }
      throw new Error('Unexpected value for WorkflowStatus:' + val);
    }

    static values(): WorkflowStatus[] {
      return [
        WorkflowStatus.ToDo,
        WorkflowStatus.InProgress,
        WorkflowStatus.Done
      ];
    }

    toJSON(): string {
      return this.name;
    }

    toString(): string {
      return this.name;
    }
}
