<div #container class="app-container accent-bg-100" fxLayout="row" fxLayoutAlign="center center">
  <div class="sidenav-container full-height mat-elevation-z11" fxLayout="column" fxLayoutAlign="center center">
    <div class="side-nav-filler-container" *ngIf="sideNavOpen" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px" (click)="sidenav.close(); this.sideNavOpen = false" [style.right.px]="getContainerRight">
      <swan-scripture class="full-width mat-elevation-z7 scripture"></swan-scripture>
    </div>
    <mat-sidenav-container class="full-width full-height" (backdropClick)="sidenav.close(); this.sideNavOpen = false" [ngClass]="{'mobile': isMobile}">
      <mat-sidenav #sidenav [position]="'end'" class="primary-bg" [autoFocus]="false" (click)="sidenav.close(); this.sideNavOpen = false">
        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="full-width" fxLayout="column" fxLayoutAlign="start center" style="cursor: pointer;" fxLayoutGap="4px"
              (click)="navigate('/account')"
              swanStats
              stat="account">
            <mat-icon class="manage-user-icon">person</mat-icon>
            <div style="font-size: 12px; margin-bottom: 18px !important;">My Account</div>
          </div>
          <button mat-button class="full-width"
                (click)="contactUs()"
                swanStats
                stat="contactUs">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
              <mat-icon class="menu-icon">contact_mail</mat-icon>
              <div fxFlex="grow" style="text-align: left;" class="menu-text">Contact</div>
            </div>
          </button>
          <button mat-button class="full-width"
                (click)="recipeClick()"
                swanStats
                stat="recipe">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
              <mat-icon class="menu-icon">import_contacts</mat-icon>
              <div fxFlex="grow" style="text-align: left;" class="menu-text">Recipes</div>
            </div>
          </button>
          <button mat-button class="full-width"
                (click)="navigate('/app/templates')"
                swanStats
                stat="templates">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
              <mat-icon class="menu-icon">view_carousel</mat-icon>
              <div fxFlex="grow" style="text-align: left;" class="menu-text">Templates</div>
            </div>
          </button>
          <button
            mat-button class="full-width"
            (click)="navigate('/app/groups')"
            swanStats
            stat="groups">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
              <div style="position: relative; width: 24px; height: 24px;">
                <mat-icon class="hide-text menu-icon">group</mat-icon>
                <div class="notification-badge invite mat-elevation-z3" *ngIf="invites && invites.length"></div>
              </div>
              <div fxFlex="grow" style="text-align: left;" class="menu-text">Groups</div>
            </div>
          </button>
          <button mat-button class="full-width"
                (click)="openFeedback()"
                swanStats
                stat="feedback">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
            <mat-icon class="menu-icon">comment</mat-icon>
            <div fxFlex="grow" style="text-align: left;" class="menu-text">Feedback</div>
          </div>
          </button>
          <button mat-button (click)="navigate('/about')" class="full-width">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
            <mat-icon svgIcon="swan" class="swan-menu-icon"></mat-icon>
            <div fxFlex="grow" style="text-align: left;" class="menu-text">About Us</div>
          </div>
          </button>
          <button mat-button class="full-width" (click)="logout()">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
            <mat-icon class="menu-icon">directions_run</mat-icon>
            <div fxFlex="grow" style="text-align: left;" class="menu-text">Sign Out</div>
          </div>
          </button>
        </div>
      </mat-sidenav>
    
      <mat-sidenav-content>
        <div class="header background-bg" fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="backUrl" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="pointer" (click)="navigateBack()">
              <mat-icon color="primary">chevron_left</mat-icon>
              <div class="primary-c" style="font-size: 16px;">{{ backUrl }}</div>
            </div>
          </div>
          <div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon color="primary" class="swan-icon" svgIcon="swan" (click)="(user) ? navigate('/app') : navigate('/')"></mat-icon>
          </div>
          <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
            <button *ngIf="user"
              swanStats
              stat="menuOpened"
              mat-icon-button
              (click)="sidenav.open(); this.sideNavOpen = true">
              <div style="position: relative; width: 24px; height: 24px;">
                <mat-icon class="hide-text">menu</mat-icon>
                <div class="notification-badge invite mat-elevation-z3" *ngIf="invites && invites.length"></div>
              </div>
            </button>
          </div>
        </div>
    
        <div *ngIf="footerOptions && footerOptions.length" class="footer background-bg mat-elevation-z2">
          <div class="full-width full-height bold-font-light" fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="footerOptions[0]"
                  matRipple
                  class="full-height pointer"
                  fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px"
                  (click)="footerClicked(footerOptions[0])">
              <mat-icon color="primary" *ngIf="footerOptions[0].icon">{{ footerOptions[0].icon }}</mat-icon>
              <div class="last-child footer-text primary-c" [ngClass]="{'no-icon': !footerOptions[0].icon}">{{ footerOptions[0].text }}</div>
            </div>
            <div *ngIf="footerOptions[1]"
                matRipple
                class="full-height pointer"
                fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px"
                (click)="footerClicked(footerOptions[1])">
              <mat-icon color="primary" *ngIf="footerOptions[1].icon">{{ footerOptions[1].icon }}</mat-icon>
              <div class="last-child footer-text primary-c" [ngClass]="{'no-icon': !footerOptions[1].icon}">{{ footerOptions[1].text }}</div>
            </div>
            <div *ngIf="footerOptions[2]"
                  matRipple
                  class="full-height pointer"
                  fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px"
                  (click)="footerClicked(footerOptions[2])">
              <mat-icon  color="primary" *ngIf="footerOptions[2].icon">{{ footerOptions[2].icon }}</mat-icon>
              <div class="last-child footer-text primary-c" [ngClass]="{'no-icon': !footerOptions[2].icon}">{{ footerOptions[2].text }}</div>
            </div>
            <div *ngIf="footerOptions[3]"
                  matRipple
                  class="full-height pointer"
                  fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px"
                  (click)="footerClicked(footerOptions[3])">
              <mat-icon  color="primary" *ngIf="footerOptions[3].icon">{{ footerOptions[3].icon }}</mat-icon>
              <div class="last-child footer-text primary-c" [ngClass]="{'no-icon': !footerOptions[3].icon}">{{ footerOptions[3].text }}</div>
            </div>
            <div *ngIf="footerOptions[4]"
                  matRipple
                  class="full-height pointer"
                  fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3px"
                  (click)="footerClicked(footerOptions[4])">
              <mat-icon color="primary" *ngIf="footerOptions[4].icon">{{ footerOptions[4].icon }}</mat-icon>
              <div class="last-child footer-text primary-c" [ngClass]="{'no-icon': !footerOptions[4].icon}">{{ footerOptions[4].text }}</div>
            </div>
          </div>
        </div>
        <main class="background-bg" [ngClass]="{'full': !(footerOptions && footerOptions.length)}">
          <router-outlet (activate)="onActivate($event)"></router-outlet>
        </main>
      </mat-sidenav-content>
    
    </mat-sidenav-container>
  </div>
</div>

<div *ngIf="showSelector" class="selector-overlay" (click)="inputUpdate()">
  <swan-selector class="amount-selector mat-elevation-z3" [@slideInOut]="''" [data]="selectorData" (selectorChange)="inputUpdate()"></swan-selector>
</div>

<div class="loading-overlay" [class.show]="loading">
  <div *ngIf="loading" class="loading-mask">
      <mat-spinner diameter="150" color="primary"></mat-spinner>
  </div>
</div>
