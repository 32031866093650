import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';
import { DataLevel } from 'src/app/classes/data-level';
import { Project } from 'src/app/classes/project';
import { User } from 'src/app/classes/user';
import moment from 'moment';
import { Group } from 'src/app/classes/group';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'swan-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  project: Project;
  dueDate: Date;
  today = new Date();
  user: User;
  groups: Group[] = [];

  constructor(private ref: MatDialogRef<EditProjectComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private taskService: TaskService,
              private dialog: MatDialog,
              private bannerService: BannerService) {
    ref.disableClose = true;
    if (data) {
      this.project = data.project;
      this.user = data.user;
      this.groups = data.groups;
    }
    if (this.project.dueDate) {
      this.dueDate = new Date(this.project.dueDate.format());
    }
  }

  ngOnInit() {
  }

  close() {
    this.ref.close();
  }

  updateDueDate() {
    this.project.dueDate = moment(this.dueDate);
    this.updateProject();
  }

  delete() {
    this.taskService.deleteProject(DataLevel.fromExprssion(this.project.accessId === this.user.uid), this.project.accessId, this.project)
    .then(() => {
    })
    this.close();
  }

  updateProject() {
    this.taskService.updateProject(DataLevel.fromExprssion(this.project.accessId === this.user.uid), this.project.accessId, this.project)
    .then()
  }

  getGroupsWithoutCurrent(): Group[] {
    const index = this.groups.findIndex(g => g.uqid === this.project.accessId);
    if (index === -1) {
      return this.groups;
    }
    return this.groups.filter(g => g.uqid !== this.project.accessId).concat(new Group({name: 'Private', uqid: this.user.uid}));
  }
}
