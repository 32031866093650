import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShoppingList } from '../classes/shopping-list';
import { User } from '../classes/user';
import { DataLevel } from '../classes/data-level';
import { ShoppingListItem } from '../classes/shopping-list-item';

const LIST = 'shopping-list';

@Injectable({
  providedIn: 'root'
})
export class ShoppingService {
  private _db: AngularFirestore;

  constructor(db: AngularFirestore) {
    this._db = db;
  }

  private getStartingRef(from: DataLevel, id: string): AngularFirestoreDocument {
    if (from === DataLevel.group) {
      return this._db.collection('group-data').doc(id);
    } else {
      return this._db.collection('user-data').doc(id);
    }
  }

  public getAllLists(user: User): Observable<ShoppingList[]> {
    const privateRef = this._db.collection('user-data').doc(user.uid).collection<ShoppingList>(LIST).valueChanges();
    const groupRefs = user.groupIds.map(gid => {
      return this._db.collection('group-data').doc(gid).collection<ShoppingList>(LIST).valueChanges();
    });
    return combineLatest([privateRef, ...groupRefs]).pipe(
      map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
      map(ret => {
        if (Array.isArray(ret)) {
          return ret.map((s: any) => {
            if (s.createDate && (s.createDate as FireTimeStamp).seconds) {
              s.createDate = new Date((s.createDate as FireTimeStamp).seconds * 1000);
            }
            if (s.shoppingDate && (s.shoppingDate as FireTimeStamp).seconds) {
              s.shoppingDate = new Date((s.shoppingDate as FireTimeStamp).seconds * 1000);
            }
            return new ShoppingList(<ShoppingList>s);
          });
        } else {
            return [];
        }
      })
    );
  }

  public getListByUqid(from: DataLevel, id: string, uqid: string): Observable<ShoppingList> {
    return this.getStartingRef(from, id).collection<ShoppingList>(LIST)
      .doc(uqid)
      .valueChanges()
      .pipe(
        map((ret: any) => {
          if (ret.createDate) {
            ret.createDate = new Date((ret.createDate as FireTimeStamp).seconds * 1000);
          }
          if (ret.shoppingDate) {
            ret.shoppingDate = new Date((ret.shoppingDate as FireTimeStamp).seconds * 1000);
          }
          return new ShoppingList(<ShoppingList>ret);
        })
      );
  }

  public updateList(from: DataLevel, id: string, list: ShoppingList): Promise<void> {
    let isNew = false;
    if (!list.uqid) {
      list.uqid = this._db.createId();
      isNew = true;
    }
    list.items.forEach((value, key) => {
      for (let i = 0; i < value.length; i++) {
        value[i].parentUqid = list.uqid;
        if (!value[i].uqid) {
          value[i].uqid = this._db.createId();
        }
      }
    });
    if (isNew) {
      return this.getStartingRef(from, id).collection(LIST).doc(list.uqid).set(list.simplify());
    } else {
      return this.getStartingRef(from, id).collection(LIST).doc(list.uqid).update(list.simplify());
    }
  }

  // tslint:disable-next-line: max-line-length
  public copyList(to: DataLevel, id: string, list: ShoppingList, categoryIdMap: Map<string, string>): Promise<void> {
    const newList = new ShoppingList();

    newList.updateList(list);
    newList.accessId = id;
    newList.uqid = this._db.createId();
    const listItems: ShoppingListItem[] = newList.getItemsAsArray().slice();
    newList.items = new Map<string, ShoppingListItem[]>();
    for (let i = 0; i < listItems.length; i++) {
      listItems[i].categoryUqid = categoryIdMap.get(listItems[i].categoryUqid);
      listItems[i].parentUqid = newList.uqid;
      const key = `${listItems[i].location}_${listItems[i].categoryUqid}`;
      if (!newList.items.get(key)) {
        newList.items.set(key, []);
      }
      newList.items.get(key).push(listItems[i]);
    }
    return this.getStartingRef(to, id).collection(LIST).doc(newList.uqid).set(newList.simplify());
  }

  public deleteList(from: DataLevel, id: string, list: ShoppingList): Promise<void> {
    return this.getStartingRef(from, id).collection(LIST).doc(list.uqid).delete();
  }

}

export interface FireTimeStamp {
  seconds?: number;
  nanoseconds?: number;
}
