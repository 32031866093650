import { Component, OnInit, Inject } from '@angular/core';
import { Trip } from 'src/app/classes/trip';
import moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { PackingCategoryTemplate } from 'src/app/classes/packing-category-template';
import { TripItem } from 'src/app/classes/trip-item';
import { User } from 'src/app/classes/user';
import { Group } from 'src/app/classes/group';
import { Project } from 'src/app/classes/project';
import { TaskService } from 'src/app/services/task.service';
import { Task } from 'src/app/classes/task';
import { DataLevel } from 'src/app/classes/data-level';
import { Router } from '@angular/router';

@Component({
  selector: 'swan-add-trip',
  templateUrl: './add-trip.component.html',
  styleUrls: ['./add-trip.component.scss']
})
export class AddTripComponent implements OnInit {

  trip: Trip;
  tripItems: TripItem[] = [];
  destination: string;
  today: Date;
  startDate: moment.Moment;
  endDate: moment.Moment;
  categories: PackingCategoryTemplate[] = [];
  selectCategories: SelectCategory[] = [];
  currentStep = 1;
  user: User;
  groups: Group[] = [];
  createProject = false;

  constructor(private ref: MatDialogRef<AddTripComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private dialog: MatDialog,
              private router: Router,
              private taskService: TaskService) {
    this.ref.disableClose = true;
    this.trip = new Trip();
    this.today = new Date();
    if (data) {
      this.categories = data.categories;
      this.user = data.user;
      this.groups = data.groups;
      this.trip.accessId = this.user.uid;
      this.categories.filter(c => c.accessId === this.user.uid).forEach((category: PackingCategoryTemplate) => {
        const select: SelectCategory = {category: category, checked: false, expanded: false, items: []};
        category.items.forEach((item) => {
          select.items.push({name: item, checked: false});
        });
        this.selectCategories.push(select);
      });
    }
  }

  ngOnInit() {
  }

  updateSelects() {
    this.selectCategories = [];
    this.categories.filter(c => c.accessId === this.trip.accessId).forEach((category: PackingCategoryTemplate) => {
      const select: SelectCategory = {category: category, checked: false, expanded: false, items: []};
      category.items.forEach((item) => {
        select.items.push({name: item, checked: false});
      });
      this.selectCategories.push(select);
    });
  }


  isValid(): boolean {
    return this.startDate && this.endDate && !!this.destination;
  }

  close() {
    this.ref.close();
  }

  goBack(stepper: MatStepper) {
    this.currentStep = 1;
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    this.currentStep = 2;
    stepper.next();
  }

  done() {
    if (this.isValid()) {
      this.trip.startDate = moment(this.startDate);
      this.trip.endDate = moment(this.endDate);
      this.trip.destination = this.destination;
      this.trip.name = `${this.destination.trim()}`;
      this.selectCategories.forEach((category) => {
          category.items.forEach((item) => {
            if (item.checked) {
              if (item.name.includes('&&')) {
                const split = item.name.split('&&');
                item.name = split[2];
                this.tripItems.push(new TripItem(
                  { name: item.name, dependencyRatio: +split[0], numberExtra: +split[1], category: category.category.uqid }
                ));
              } else {
                this.tripItems.push(new TripItem({name: item.name, dependencyRatio: 0, numberExtra: 0, category: category.category.uqid}));
              }
            }
          });
      });
      this.tripItems.forEach((item) => {
        const key = item.category;
        if (!this.trip.items.get(key)) {
          this.trip.items.set(key, []);
        }
        this.trip.items.get(key).push(item);
      });
      this.ref.close(this.trip);
    }
  }

  categoryChecked(category: SelectCategory) {
    category.items.forEach((item) => {
      item.checked = category.checked;
    });
    category.expanded = category.checked;
  }

  displayItem(item: SelectCategoryItem): string {
    if (item.name.includes('&&')) {
      const split = item.name.split('&&');
      if (this.startDate && this.endDate) {
        return `${Math.ceil(moment(this.endDate).diff(moment(this.startDate), 'days') * +split[0]) + +split[1]} ${split[2]}`;
      } else {
        return `(XX) ${split[2]}`;
      }
    } else {
      return item.name;
    }
  }
}

export interface SelectCategory {
  category: PackingCategoryTemplate;
  checked?: boolean;
  expanded?: boolean;
  items?: SelectCategoryItem[];
}

export interface SelectCategoryItem {
  name?: string;
  checked?: boolean;
}
