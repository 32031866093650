<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <div class="modal-header full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" class="modal-title">Comments</div>
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="close()">
        <div class="modal-close-text">Close</div>
        <mat-icon class="modal-close-icon" color="accent">clear</mat-icon>
      </div>
    </div>
  </div>
  <div class="modal-content full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="18px">
    <div class="full-width" *ngIf="task.comments.length > 0" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="full-width mat-elevation-z3" style="padding: 8px; border-radius: 10px;" *ngFor="let comment of task.comments; let i = index" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon color="accent" [matTooltip]="comment.commentor" matTooltipPosition="right"
            (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="$event.stopImmediatePropagation()"
            #tooltip="matTooltip" (click)="tooltip.toggle()"
            [color]="(comment.commentor === user.email) ? 'primary' : 'accent'">person_pin</mat-icon>
        <div fxFlex="grow" class="light-font-small" style="white-space: pre-wrap;">{{ comment.message }}</div>
      </div>
    </div>
    <mat-form-field class="full-width" color="accent" appearance="outline">
      <mat-label>New Comment</mat-label>
      <textarea matInput rows="2" [(ngModel)]="comment.message"></textarea>
    </mat-form-field>
    <button class="full-width" mat-raised-button color="accent" [disabled]="!comment.message" (click)="pushComment()">Add Comment</button>
  </div>
  <div class="modal-footer full-width" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
      <div class="pointer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px" (click)="done()">
        <div class="modal-footer-text">Done</div>
        <mat-icon class="modal-footer-icon">check</mat-icon>
      </div>
    </div>
  </div>
</div>
