<div class="fill-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
  <div class="title accent-c">Swistible</div>
  <div class="full-width login-container" style="padding: 20px;" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
      <div class="full-width" *ngIf="isSigningUp && publicAccess" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <mat-icon class="back-login-icon pointer" (click)="isSigningUp = false">chevron_left</mat-icon>
        <div class="back-login-text pointer" (click)="isSigningUp = false">Back to login page</div>
      </div>
      <mat-form-field class="small-form full-width" color="accent" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="email" (keyup.enter)="(isSigningUp) ? signup() : login()">
      </mat-form-field>
      <mat-form-field class="small-form full-width" color="accent" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput [(ngModel)]="password" type="password" (keyup.enter)="(isSigningUp) ? signup() : login()">
      </mat-form-field>
      <mat-form-field class="small-form full-width" color="accent" appearance="outline" *ngIf="isSigningUp">
        <mat-label>Confirm Password</mat-label>
        <input matInput [(ngModel)]="confirmPassword" type="password" (keyup.enter)="(isSigningUp) ? signup() : login()">
      </mat-form-field>
      <div class="full-width error warn-c" *ngIf="!password && error">Email or Password is invalid!</div>
      <div *ngIf="isSigningUp" class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon color="primary" class="pass-require-icon" *ngIf="confirmPassword && confirmPassword === password">check</mat-icon>
          <mat-icon color="warn" class="pass-require-icon" *ngIf="!confirmPassword || confirmPassword !== password">clear</mat-icon>
          <div fxFlex="grow" style="font-size: 12px; color: #a6a6a6; font-style: italic;">Confirmed password matches</div>
        </div>
        <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon color="primary" class="pass-require-icon" *ngIf="passLengthCheck()">check</mat-icon>
          <mat-icon color="warn" class="pass-require-icon" *ngIf="!passLengthCheck()">clear</mat-icon>
          <div fxFlex="grow" style="font-size: 12px; color: #a6a6a6; font-style: italic;">Password must be 6 characters or greater</div>
        </div>
      </div>
      <div *ngIf="!isSigningUp && publicAccess" class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
          <div class="accent-c" style="font-size: 12px; cursor: pointer;" (click)="isSigningUp = true; confirmPassword = ''">Need an account? Sign up here.</div>
        </div>
        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="end center">
          <div class="accent-c" style="font-size: 12px; cursor: pointer;" (click)="resetPassword()">Reset Password</div>
        </div>
      </div>
      <button mat-raised-button color="primary" class="full-width" [disabled]="!canContinue()" (click)="(isSigningUp) ? signup() : login()">{{ (isSigningUp) ? 'Sign Up' : 'Login' }}</button>
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <div matRipple class="bottom-chip accent-bg-300 about mat-elevation-z1" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px" (click)="navigateAbout()">
          <mat-icon svgIcon="swan" color="primary"></mat-icon>
          <div>What is Swistible?</div>
        </div>
      </div>
  
  </div>
  <div class="full-width" style="flex: 1;"></div>
  <div  *ngIf="!isSigningUp && publicAccess" class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap=" 16px">
    <button mat-raised-button class="full-width social-login-button" (click)="googleLogin()">
      <div class="social-login-text">Continue With Google</div>
      <img class="social-login-img" src="assets/login/google_login.png">
    </button>
    <button mat-raised-button class="full-width social-login-button" (click)="facebookLogin()">
      <div class="social-login-text">Continue With Facebook</div>
      <img class="social-login-img" src="assets/login/facebook_login.png">
    </button>
  </div>
  <div class="full-width terms accent-c">By logging in you acknowledge that you have read and accept our <span><a href="https://firebasestorage.googleapis.com/v0/b/swanscript.appspot.com/o/swistible%2Fdocuments%2Fprivacy-policy.pdf?alt=media&token=45e6bca2-40e9-4678-a727-8afc8ab5478c" target="_blank">Privacy Policy</a></span> and <span><a href="https://firebasestorage.googleapis.com/v0/b/swanscript.appspot.com/o/swistible%2Fdocuments%2Fterms-and-conditions.pdf?alt=media&token=bbafcf57-71db-437c-9b1a-2183bb674137" target="_blank">Terms and Conditions</a></span>.</div>

</div>
