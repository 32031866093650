import { trimIfExists } from '../utils/string-utils';

export class RecipeItem {
  public name?: string;
  public categoryUqid?: string;
  public parentUqid?: string;
  public uqid?: string;

  constructor(item?: any) {
    this.name = (item && item.name) ? item.name : '';
    this.categoryUqid = (item && item.categoryUqid) ? item.categoryUqid : '';
    this.parentUqid = (item && item.parentUqid) ? item.parentUqid : undefined;
    this.uqid = (item && item.uqid) ? item.uqid : undefined;
  }

  public simplify(): any {
    return {
      name: this.name,
      categoryUqid: trimIfExists(this.categoryUqid),
      parentUqid: trimIfExists(this.parentUqid),
      uqid: trimIfExists(this.uqid)
    };
  }
}
