import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FireTimeStamp } from './shopping.service';
import { TemplateService } from './template.service';
import { AuthService } from '../modules/core/auth/auth.service';
import { map } from 'rxjs/operators';
import { DataLevel } from '../classes/data-level';
import { Feedback } from '../classes/feedback';
import { Observable } from 'rxjs';
import { Email } from '../classes/email';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private _db: AngularFirestore;

  constructor(db: AngularFirestore,
              private templateService: TemplateService) {
    this._db = db;
  }

  submitFeedback(feedback: Feedback): Promise<void> {
    feedback.uqid = this._db.createId();
    return this._db.collection('feedback').doc(feedback.uqid).set(feedback.simplify());
  }

  submitEmail(email: Email): Promise<void> {
    email.uqid = this._db.createId();
    return this._db.collection('email').doc(email.uqid).set(email.simplify());
  }
}
