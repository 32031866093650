import { trimIfExists } from '../utils/string-utils';

export class Invite {
  public groupId?: string;
  public groupName?: string;
  public recipientEmail?: string;
  public sender?: string;
  public uqid?: string;

  constructor(invite?: any) {
    this.groupId = (invite && invite.groupId) ? invite.groupId : '';
    this.groupName = (invite && invite.groupName) ? invite.groupName : '';
    this.recipientEmail = (invite && invite.recipientEmail) ? invite.recipientEmail : '';
    this.sender = (invite && invite.sender) ? invite.sender : '';
    this.uqid = (invite && invite.uqid) ? invite.uqid : undefined;
  }

  public simplify(): any {
    return {
      groupId: trimIfExists(this.groupId),
      groupName: trimIfExists(this.groupName),
      recipientEmail: trimIfExists(this.recipientEmail),
      sender: trimIfExists(this.sender),
      uqid: trimIfExists(this.uqid),
    };
  }
}
