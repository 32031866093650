import moment from 'moment';
import { trimIfExists } from '../utils/string-utils';

export class Feedback {
  public message?: string;
  public submitDate?: moment.Moment;
  public type?: string;
  public userUqid?: string;
  public uqid?: string;

  constructor(feedback?: any) {
    this.message = (feedback && feedback.message) ? feedback.message : '';
    this.submitDate = (feedback && feedback.submitDate) ? moment(feedback.submitDate) : moment();
    this.type = (feedback && feedback.type) ? feedback.type : '';
    this.userUqid = (feedback && feedback.userUqid) ? feedback.userUqid : undefined;
    this.uqid = (feedback && feedback.uqid) ? feedback.uqid : undefined;
  }

  public simplify(): any {
    return {
      message: trimIfExists(this.message),
      submitDate: this.submitDate.toDate(),
      type: trimIfExists(this.type),
      userUqid: trimIfExists(this.userUqid),
      uqid: trimIfExists(this.uqid)
    };
  }
}
