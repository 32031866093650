import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Task } from 'src/app/classes/task';
import { TaskService } from 'src/app/services/task.service';
import { DataLevel } from 'src/app/classes/data-level';
import { User } from 'src/app/classes/user';
import { Priority } from 'src/app/classes/priority';
import { WorkflowStatus } from 'src/app/classes/workflow-status';
import { Project } from 'src/app/classes/project';

@Component({
  selector: 'swan-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss']
})
export class EditTaskComponent implements OnInit {
  task: Task;
  user: User;
  Priority = Priority;
  WorkflowStatus = WorkflowStatus;
  canChangeStatus = true;
  project: Project;

  constructor(private ref: MatDialogRef<EditTaskComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private taskService: TaskService) {
    ref.disableClose = true;
    if (data) {
      this.project = data.project;
      this.task = data.task;
      this.user = data.user;
      this.canChangeStatus = data.canChangeStatus;
    }
  }

  ngOnInit() {
  }

  done() {
    if (this.taskIsValid) {
      this.ref.close(this.task);
    }
  }

  close() {
    this.ref.close();
  }

  updateTask() {
    if (this.project) {
      this.taskService.updateProject(DataLevel.fromExprssion(this.project.accessId === this.user.uid), this.project.accessId, this.project)
      .then()
    } else {
      this.taskService.updateTask(DataLevel.fromExprssion(this.task.accessId === this.user.uid), this.task.accessId, this.task)
      .then()
    }
  }

  delete() {
    if (this.project) {
      const index = this.project.tasks.findIndex(t => t.uqid === this.task.uqid);
      if (index !== -1) {
        this.project.tasks.splice(index, 1);
        this.updateTask();
      }
    } else {
      this.taskService.deleteTask(DataLevel.fromExprssion(this.task.accessId === this.user.uid), this.task.accessId, this.task).then(() => {
      })
    }
    this.close();
  }

  get taskIsValid(): boolean {
    return !!this.task.name;
  }

  deleteComment(index: number) {
    this.task.comments.splice(index, 1);
  }

}
