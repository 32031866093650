import { ShoppingListItem } from './shopping-list-item';
import moment from 'moment';
import { trimIfExists } from '../utils/string-utils';

export class ShoppingList {
  public accessId?: string;
  public createDate?: moment.Moment;
  public items?: Map<string, ShoppingListItem[]>;
  public locations?: string[];
  public name?: string;
  public notes?: string;
  public shoppingDate?: moment.Moment;
  public shoppingMode?: boolean = false;
  public uqid?: string;


  constructor(list?: any) {
    this.accessId = (list && list.accessId) ? list.accessId : undefined;
    this.createDate = (list && list.createDate) ? moment(list.createDate) : moment();
    this.items = (list && list.items) ? this.initItems(list.items) : new Map<string, ShoppingListItem[]>();
    this.locations = (list && list.locations) ? list.locations : [];
    this.name = (list && list.name) ? list.name : '';
    this.notes = (list && list.notes) ? this.fixNotes(list.notes) : '';
    this.shoppingDate = (list && list.shoppingDate) ? moment(list.shoppingDate) : moment();
    this.shoppingMode = (list && list.shoppingMode !== undefined) ? list.shoppingMode : false;
    this.uqid = (list && list.uqid) ? list.uqid : undefined;
  }

  private fixNotes(notes: any): string {
    if (Array.isArray(notes)) {
      return notes.map(n => `- ${n}`).join("\n");
    } else {
      return notes
    }
  }

  private initItems(incomingItems: any): Map<string, ShoppingListItem[]> {
    const items = new Map<string, ShoppingListItem[]>();
    if (incomingItems instanceof Map) {
      incomingItems.forEach((value, key) => {
        items.set(key, value);
      });
    } else {
      const keys = Object.keys(incomingItems);
      keys.forEach((key) => {
        items.set(key, incomingItems[key].map(i => new ShoppingListItem(i)));
      });
    }
    return items;
  }

  private simplifyItems(): any {
    const ret = Array.from(this.items).reduce((o, [key, value]) => (
      Object.assign(o, { [key]: value.map(i => i.simplify()) })
    ), {});
    return ret;
  }

  public simplify(): any {
    return {
      accessId: trimIfExists(this.accessId),
      createDate: this.createDate.toDate(),
      items: this.simplifyItems(),
      locations: this.locations,
      name: trimIfExists(this.name),
      notes: trimIfExists(this.notes),
      shoppingDate: this.shoppingDate.toDate(),
      shoppingMode: this.shoppingMode,
      uqid: trimIfExists(this.uqid),
    };
  }


  public updateList(list: ShoppingList) {
    this.locations = list.locations;
    this.notes = list.notes;
    this.name = list.name;
    this.shoppingMode = list.shoppingMode;
    this.shoppingDate = list.shoppingDate;
    this.createDate = moment();
    const newItems = list.getItemsAsArray();
    const oldItems = this.getItemsAsArray();
    for (let i = 0; i < newItems.length; i++) {
      const sli = oldItems.find(item => item.uqid === newItems[i].uqid);
      if (sli) {
        sli.updateItem(newItems[i]);
      } else {
        const key = `${newItems[i].location}_${newItems[i].categoryUqid}`;
        if (!this.items.get(key)) {
          this.items.set(key, []);
        }
        this.items.get(key).push(newItems[i]);
      }
    }
    for (let i = 0; i < oldItems.length; i++) {
      const sli = newItems.find(item => item.uqid === oldItems[i].uqid);
      if (!sli) {
        const key = `${oldItems[i].location}_${oldItems[i].categoryUqid}`;
        const items = this.items.get(key);
        const index = items.findIndex(j => j.uqid === oldItems[i].uqid);
        if (index !== -1) {
          items.splice(index, 1);
        }
      }
    }
  }

  public getItemsAsArray(): ShoppingListItem[] {
    const ret: ShoppingListItem[] = [];
    this.items.forEach((value, key) => {
      ret.push(...value);
    });
    return ret;
  }

  public deleteItem(item: ShoppingListItem) {
    const section = this.items.get(`${item.location}_${item.categoryUqid}`);
    if (section) {
      const index = section.findIndex(s => s.uqid === item.uqid);
      if (index !== -1) {
        section.splice(index, 1);
      }
    }
  }

  public get noProgress(): boolean {
    const itemArr = this.getItemsAsArray();
    const numChecked = itemArr.map(i => (i.checked) ? 1 : 0).filter(i => i > 0).length;
    return numChecked === 0 || itemArr.length === 0
  }

  public get totalItems(): number {
    return this.getItemsAsArray().length;

  }

  public get numberCheckedString(): string {
    const itemArr = this.getItemsAsArray();
    return `${itemArr.map(i => (i.checked) ? 1 : 0).filter(i => i > 0).length} / ${itemArr.length}`;
  }

  public get numberChecked(): number {
    return this.getItemsAsArray().filter(i => i.checked).length;
  }

  public get isDone(): boolean {
    const itemArr = this.getItemsAsArray();
    const numChecked = itemArr.map(i => (i.checked) ? 1 : 0).filter(i => i > 0).length;
    return (numChecked === itemArr.length && itemArr.length > 0);
  }

  moveItem(changedItem: ShoppingListItem) {
    let categoryItems: ShoppingListItem[] = this.items.get(`${changedItem.location}_${changedItem.categoryUqid}`);
    if (!categoryItems) {
      this.items.set(`${changedItem.location}_${changedItem.categoryUqid}`, []);
      categoryItems = this.items.get(`${changedItem.location}_${changedItem.categoryUqid}`);
    }
    if (!categoryItems.find(i => i.uqid === changedItem.uqid)) {
      this.items.get(`${changedItem.location}_${changedItem.categoryUqid}`).push(changedItem);
      this.items.forEach((value, key) => {
        const index = value.findIndex(v => v.uqid === changedItem.uqid && key !== `${changedItem.location}_${changedItem.categoryUqid}`);
        if (index !== -1) {
          value.splice(index, 1);
          return;
        }
      });
    }
  }
}
