import { trimIfExists } from '../utils/string-utils';

export class PackingCategoryTemplate {
  public accessId?: string;
  public icon?: string;
  public items?: string[];
  public name?: string;
  public uqid?: string;

  constructor(template?: any) {
    this.accessId = (template && template.accessId) ? template.accessId : undefined;
    this.icon = (template && template.icon) ? template.icon : '';
    this.items = (template && template.items) ? template.items : [];
    this.name = (template && template.name) ? template.name : '';
    this.uqid = (template && template.uqid) ? template.uqid : undefined;
  }

  public simplify(): any {
    return {
      accessId: trimIfExists(this.accessId),
      icon: trimIfExists(this.icon),
      items: this.items,
      name: trimIfExists(this.name),
      uqid: trimIfExists(this.uqid),
    };
  }
}
