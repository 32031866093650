import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private _db: AngularFirestore;

  private enabled = false;

  constructor(private db: AngularFirestore) {
    this._db = db;
    this._db.collection('settings').doc('stats').get().subscribe((doc) => {
      if (doc && doc.exists && doc.data()) {
        this.enabled = doc.data()['enabled'] as boolean;
      } else {
        this.enabled = false;
      }
    })
  }

  public incrementStat(key: string) {
    if (this.enabled) {
      const data = {};
      data[key] = firebase.firestore.FieldValue.increment(1);
      this._db.collection('statistics').doc("counts").update(data);
    }
  }
}
