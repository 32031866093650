import { AuthGuard } from './modules/core/auth/auth.guard';
import { LoginComponent } from './modules/core/login.component';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { Routes } from '@angular/router';
import { UserManagerComponent } from './components/user-manager/user-manager.component';

export const appRoutes: Routes = [
  {
    path : '',
    redirectTo: 'app',
    pathMatch: 'full'
  }, {
    path : 'about',
    component: AboutPageComponent,
    data: {title: 'About'}
  }, {
    path : 'account',
    component: UserManagerComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Account'
    }
  }, {
    path : 'app',
    loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule)
  }, {
    path : 'login',
    component : LoginComponent,
    data: {title: 'Login'}
  }, {
    path : '**',
    redirectTo: 'app',
    pathMatch: 'full'
  }
];
