export class DataLevel {
  static readonly private: DataLevel = new DataLevel('private');
  static readonly group: DataLevel = new DataLevel('group');

  public readonly name: string;

  private constructor(name: string) {
    this.name = name;
  }

  static fromExprssion(expression: boolean): DataLevel {
    return (expression) ? DataLevel.private : DataLevel.group;
  }

  static forName(name: string) {
    if (name === 'private') { return DataLevel.private; }
    if (name === 'group') { return DataLevel.group; }
    throw new Error('Unexpected value for DataLevel:' + name);
  }

  static values(): DataLevel[] {
    return [
      DataLevel.private,
      DataLevel.group
    ];
  }

  toJSON(): string {
    return this.name;
  }

  toString(): string {
    return this.name;
  }
}
