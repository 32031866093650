import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Comment } from 'src/app/classes/comment';
import { DataLevel } from 'src/app/classes/data-level';
import { Project } from 'src/app/classes/project';
import { Task } from 'src/app/classes/task';
import { User } from 'src/app/classes/user';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'swan-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {

  comment: Comment;
  user: User;
  task: Task;
  project: Project;

  constructor(private ref: MatDialogRef<AddCommentComponent>,
              private taskService: TaskService,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.comment = new Comment();
    if (data) {
      this.project = data.project;
      this.task = data.task;
      this.user = data.user;
    }
  }

  ngOnInit() {
  }

  updateTask() {
    if (this.project) {
      this.taskService.updateProject(DataLevel.fromExprssion(this.project.accessId === this.user.uid), this.project.accessId, this.project)
      .then()
    } else {
      this.taskService.updateTask(DataLevel.fromExprssion(this.task.accessId === this.user.uid), this.task.accessId, this.task)
      .then()
    }
  }

  close() {
    this.ref.close();
  }

  pushComment() {
    this.comment.commentor = this.user.email;
    this.task.comments.push(this.comment);
    this.comment = new Comment();
    this.updateTask();
  }

  done() {
    this.ref.close();
  }

}
