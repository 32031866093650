import moment from 'moment';
import { Task } from './task';
import { trimIfExists } from '../utils/string-utils';
import { FireTimeStamp } from '../services/shopping.service';
import { ORDER_STEP, START_ORDER } from '../modules/home/home.component';
import { WorkflowStatus } from './workflow-status';

export class Project {
    public accessId?: string;
    public createDate?: moment.Moment;
    public description?: string;
    public dueDate?: moment.Moment;
    public name?: string;
    public tasks?: Task[];
    public uqid?: string;

    constructor(project?: any) {
      this.accessId = (project && project.accessId) ? project.accessId : undefined;
      this.createDate = (project && project.createDate) ? moment(project.createDate) : moment();
      this.description = (project && project.description) ? project.description : '';
      this.dueDate = (project && project.dueDate) ? moment(project.dueDate) : moment().add(30, 'days');
      this.name = (project && project.name) ? project.name : '';
      this.tasks = (project && project.tasks) ? project.tasks.map(t => this.taskFromStoredTask(t)) : [];
      this.uqid = (project && project.uqid) ? project.uqid : undefined;
    }

    public simplify(): any {
      const ret: any = {
        accessId: trimIfExists(this.accessId),
        createDate: this.createDate.toDate(),
        dueDate: this.dueDate.toDate(),
        name: trimIfExists(this.name),
        tasks: this.tasks.map(t => t.simplify()),
        uqid: trimIfExists(this.uqid)
      };
      if (this.description) {
        ret.description = trimIfExists(this.description);
      }
      return ret;
    }

    taskFromStoredTask(task: any) {
      if (task.createDate) {
        task.createDate = new Date((task.createDate as FireTimeStamp).seconds * 1000);
      }
      if (task.dueDate) {
        task.dueDate = new Date((task.dueDate as FireTimeStamp).seconds * 1000);
      }
      return new Task(task);
    }

    public updateProject(project: Project) {
        this.accessId = project.accessId;
        this.createDate = project.createDate;
        this.description = project.description;
        this.dueDate = project.dueDate;
        this.name = project.name;
        this.uqid = project.uqid;
        project.tasks.forEach((task) => {
          const oldTask = this.tasks.find(t => t.uqid === task.uqid);
          if (oldTask) {
              oldTask.updateTask(task);
          } else {
              this.tasks.push(task);
          }
        });

        const tasksToRemove: Task[] = [];
        this.tasks.forEach((task) => {
          if (!project.tasks.find(t => t.uqid === task.uqid)) {
              tasksToRemove.push(task);
          }
        });

        tasksToRemove.forEach((task) => {
          this.removeTask(task);
        });
    }

    public getIndexFromUqid(uqid: string): number {
      return this.tasks.findIndex(t => t.uqid === uqid);
    }

    public getTaskFromUqid(uqid: string): Task {
      return this.tasks.find(t => t.uqid === uqid);
    }

    public removeTask(task: Task) {
        const index = this.getIndexFromUqid(task.uqid);
        if (index !== -1) {
            this.tasks.splice(index, 1);
        }
    }

    public nextOrder(): number {
      if (this.tasks.length) {
        return Math.min(...this.tasks.filter(t => t.status === WorkflowStatus.ToDo).map(t => t.order)) - ORDER_STEP;
      } else {
        return START_ORDER;
      }
    }
}
