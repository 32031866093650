import { trimIfExists } from '../utils/string-utils';

export class Comment {
  public commentor?: string;
  public message?: string;

  constructor(comment?: any) {
    this.commentor = (comment && comment.commentor) ? comment.commentor : '';
    this.message = (comment && comment.message) ? comment.message : '';
  }

  simplify(): any {
    return {
      commentor: trimIfExists(<string>this.commentor),
      message: trimIfExists(<string>this.message)
    };
  }
}
