<div class="fill-container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width user-forms-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon class="person-icon" color="accent">person</mat-icon>
        <div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="full-width accent-c">Login Information:</div>
            <div class="full-width mat-elevation-z3" style="padding: 8px; border-radius: 10px;" fxLayout="column" fxLayoutAlign="start center">
                <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <mat-form-field fxFlex="grow" color="accent" appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" [(ngModel)]="updatedEmail" [disabled]="!changeEmail">
                    </mat-form-field>
                    <button *ngIf="!provider || (provider !== 'google.com' && provider !== 'facebook.com')" mat-button color="accent" (click)="toggleEditEmail()">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                            <div>{{ (changeEmail) ? 'Cancel' : 'Edit' }}</div>
                            <mat-icon class="email-change-icon">{{ (changeEmail) ? 'clear' : 'edit' }}</mat-icon>
                        </div>
                    </button>
                </div>
                <mat-form-field *ngIf="changePassword" class="full-width" color="accent" appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" [(ngModel)]="newPassword">
                </mat-form-field>
                <mat-form-field *ngIf="changePassword" class="full-width" color="accent" appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" [(ngModel)]="newPasswordConfirmed">
                </mat-form-field>
                <div *ngIf="changePassword" class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                      <mat-icon color="primary" class="pass-require-icon" *ngIf="newPasswordConfirmed && newPasswordConfirmed === newPassword">check</mat-icon>
                      <mat-icon color="warn" class="pass-require-icon" *ngIf="!newPasswordConfirmed || newPasswordConfirmed !== newPassword">clear</mat-icon>
                      <div fxFlex="grow" style="font-size: 12px; color: #a6a6a6; font-style: italic;">Confirmed password matches</div>
                    </div>
                    <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                      <mat-icon color="primary" class="pass-require-icon" *ngIf="passLengthCheck()">check</mat-icon>
                      <mat-icon color="warn" class="pass-require-icon" *ngIf="!passLengthCheck()">clear</mat-icon>
                      <div fxFlex="grow" style="font-size: 12px; color: #a6a6a6; font-style: italic;">Password must be 6 characters or greater</div>
                    </div>
                </div>
            </div>
            <div *ngIf="!provider || (provider !== 'google.com' && provider !== 'facebook.com')" class="full-width" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
                <div class="primary-c" style="font-size: 12px; cursor: pointer;" (click)="toggleChangePassword()">Change Password</div>
            </div>
        </div>
        <!-- <div class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="4px">
            <div class="full-width accent-c">Membership:</div>
            <div class="full-width mat-elevation-z3" style="padding: 8px; border-radius: 10px;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <mat-icon *ngIf="displayMembership === 'Basic'" class="basic-icon" svgIcon="swan" color="primary"></mat-icon>
                <swan-premium-icon *ngIf="displayMembership === 'Gold' || displayMembership === 'Silver'" size="40" background="normal" [level]="displayMembership.toLowerCase()"></swan-premium-icon>
                <div fxFlex="grow">{{ displayMembership }}</div>
                <button mat-button color="accent" (click)="upgrade()" *ngIf="displayMembership !== 'Silver'"> Upgrade </button>
            </div>
            <div *ngIf="user && (user.hasSilverPerks() || user.hasGoldPerks())" class="full-width" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
                <a href="https://paypal.com" target="_blank" class="primary-c" style="font-size: 12px; cursor: pointer;">
                    Cancel your subscription through Paypal's website
                </a>
            </div>
        </div> -->
        <button mat-button color="warn" class="full-width" (click)="delete()">Delete Account</button>
    </div>
    <div *ngIf="changeEmail || changePassword" class="full-width confirm-container primary-border-top" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
        <mat-form-field fxFlex="grow"  color="accent" appearance="outline">
            <mat-label>Enter Current Password</mat-label>
            <input matInput [(ngModel)]="saveChangesPass" type="password">
        </mat-form-field>
        <button mat-raised-button fxFlex="124px" color="accent" [disabled]="!canSaveChanges()" (click)="authenticateForChanges()">Save Changes</button>
    </div>
</div>
