<div class="width-padding-10" style="margin: 0 important!;" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
  <div class="bold-font-dark">This is a Web App!</div>
  <div style="color: #a6a6a6; white-space: nowrap;">For easy access to this site</div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div class="light-font-small" style="white-space: nowrap;">Tap</div>
    <mat-icon style="color: #006EFF" svgIcon="ios_share"></mat-icon>
    <div class="light-font-small" style="white-space: nowrap;">then</div>
    <mat-icon style="color: #7F8C8D" svgIcon="add_to_home"></mat-icon>
    <div class="light-font-small" style="white-space: nowrap;">Add to Home Screen</div>
  </div>
  <button class="full-width" mat-button color="accent" (click)="close()">Dismiss</button>
</div>
